import React from 'react';

export const ApiSearchField = ({searchQuery, setSearchQuery}) => {
    return (
        <div>
            Search : {' '}
            <input className="ml-2 input-search form-control"
                   value={searchQuery} onChange={e => setSearchQuery(e.target.value)} style={{width: "20%"}}
            />
        </div>
    )
}
