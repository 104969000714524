import {
  apiDelete,
  apiDeleteMethod,
  apiGet,
  apiPost,
  apiPostFile,
  apiPut,
} from "./Api";
import { appUrls } from "../config/Constants";
import { loginAgain } from "../utils/SharedFunctions";

const API_VERSION = "v4";

export async function loginCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/auth/login/admin`, data);
}

export async function refreshTokenCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/auth/refresh`, data);
}

export async function resetPasswordCall(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/password-reset`,
    data
  );
}

export async function updatePasswordCall(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/password-change`,
    data
  );
}

export async function updateUser(data,userId) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/users/${userId}`, data);
}

export async function fetchEducationsCall(page = 1, size = 10, filters) {
  let url =
    appUrls.backend_api + `${API_VERSION}/educations?page=${page}&size=${size}`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllEducationsLevelsCall() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/educations/levels`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createEducationInstitutionCall(data) {
  return apiPost(appUrls.backend_api + `${API_VERSION}/educations`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateEducationalInstitutionCall(id, data) {
  return apiPut(appUrls.backend_api + `${API_VERSION}/educations/${id}`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteEducationalInstitutionCall(id) {
  return apiDelete(appUrls.backend_api + `${API_VERSION}/educations/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function checkOneTimeAuthTokenCall(data) {
  return apiPost(appUrls.backend_api + "check_one_time_token", data);
}

export async function getInStoreActionsCall() {
  return apiGet(appUrls.backend_api + "valid_actions")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getOnlineActionsCall() {
  return apiGet(appUrls.backend_api + "cashback/cashback_programs_local")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchTestUsersNotificationCall() {
  return apiGet(appUrls.backend_api + "fetch_test_users_notification")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function sendNotificationCall(data, test) {
  let url = appUrls.backend_api;
  if (test === true) url += "new_test_notification";
  else url += "new_production_notification";
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchFilterProductionNotificationCall() {
  return apiGet(appUrls.backend_api + "fetch_filter_production_notification")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllNotificationsCall() {
  return apiGet(appUrls.backend_api + "get_all_notifications")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateExpirationDateCodeCall(data) {
  return apiPost(
    appUrls.backend_api + "admin/update_expiration_date_code",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addNewKnaekIdsCall(data) {
  return apiPost(appUrls.backend_api + "admin/add_new_knaek_ids", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllActionsCall() {
  return apiGet(appUrls.backend_api + "valid_actions")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateRatingActionCall(data) {
  return apiPut(appUrls.backend_api + "update_rating_action", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateRatingActionExpCall(data) {
  return apiPost(appUrls.backend_api + "update_rating_action_exp", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipCancellationActiveReminderAdminCall(
  data
) {
  return apiPut(
    appUrls.backend_api +
      "update_membership_cancellation_active_reminder_admin",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchLastUpdateNotificationCall(id) {
  return apiGet(appUrls.backend_api + "get_last_update_of_notification/" + id)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllCitiesCall() {
  return apiGet(appUrls.backend_api + "cities")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllIntrosEventsCall(data) {
  return apiGet(appUrls.backend_api + "intro_pages", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createIntroEventCall(data) {
  return apiPost(appUrls.backend_api + "intro_page", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function unsubscribeMemberCall(data) {
  return apiPost(appUrls.backend_api + "unsubscribe_member_admin", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllMembershipCancellationsAdminCall() {
  return apiGet(appUrls.backend_api + "membership_cancellations_admin")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      loginAgain(err);
      throw err;
    });
}

export async function getAllMembershipRegistrationSystemUserCall() {
  return apiGet(appUrls.backend_api + "membership_registration_system_users")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addNewMembershipRegistrationSystemUserCall(data) {
  return apiPost(
    appUrls.backend_api + "membership_registration_system_user",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipRegistrationSystemUserCall(data) {
  return apiPut(
    appUrls.backend_api + "membership_registration_system_user",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllMembershipFormOrderCall() {
  return apiGet(appUrls.backend_api + "membership_form_order")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchKnaekIdCall(knaekId) {
  return apiGet(
    appUrls.backend_api + "activation_codes/valid_and_available?code=" + knaekId
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllLocationsCall() {
  return apiGet(appUrls.backend_api + "locationsmap")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllLocalStoresCall(page = 1, size = 10, filters) {
  let url = `sniptech/stores_locals?page=${page}&size=${size}&consumer=admin`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(appUrls.backend_api + url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllOrdersCall() {
  return apiGet(appUrls.backend_api + "membership_orders")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function uploadImageToS3Call(id, data) {
  return apiPostFile(appUrls.backend_api + `sniptech/${id}/upload`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      if (err.response?.data?.error.includes("authentication invalid")) {
        loginAgain(err);
      }
      throw err;
    });
}

export async function toggleDisabledStoreCall(data) {
  return apiPost(
    appUrls.backend_api + "sniptech/toggle-disabled-ordering",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateStorePriorityCall(data) {
  return apiPost(appUrls.backend_api + "sniptech/update-ordering", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateGeoLocationCall(data) {
  return apiPut(appUrls.backend_api + "update_geo_location", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      loginAgain(err);
      throw err;
    });
}

export async function searchUserCall(data) {
  let url = appUrls.backend_api + "v4/users/search";
  return apiPost(url, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error is here");
      console.log(err);
      throw err;
    });
}


export async function cancelMembershipForUser(data) {
  return apiPost(appUrls.backend_api + "unsubscribe_member_admin", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteUser(userId) {
  return apiDelete(appUrls.backend_api + `v4/users/${userId}/admin`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateMembershipCancellation(data) {
  return apiPut(
    appUrls.backend_api +
      "update_membership_cancellation_active_reminder_admin",
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fixAmgAccountCall(data) {
  return apiPost(appUrls.backend_api + "fix_amg_account", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchAllCountryCodesCall() {
  return apiGet(appUrls.backend_api + "country_codes/web")
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteCountryCodeCall(id) {
  return apiDeleteMethod(appUrls.backend_api + `country_codes/${id}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function addCountryCodeCall(data) {
  return apiPost(appUrls.backend_api + "country_codes", data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getUserDetailsById(userId) {
  return apiGet(appUrls.backend_api + `${API_VERSION}/users/${userId}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getChannels() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/channels`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getCountries() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/countries`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getCities() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/cities`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getEducationInstitutions() {
  return apiGet(appUrls.backend_api + `${API_VERSION}/educations/institutions`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function getAllAdminUsers(data, filters) {
  let url =
    appUrls.backend_api + `admin/users?page=${data.page}&size=${data.size}`;
  if (filters?.length > 0) {
    url += `&${filters}`;
  }
  return apiGet(url)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function updateAdminUser(data) {
  return apiPut(appUrls.backend_api + `admin/users`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function deleteAdminUserCall(data) {
  return apiPost(appUrls.backend_api + `admin/users`, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}

export async function createUserAdmin(data) {
  return apiPost(
    appUrls.backend_api + `${API_VERSION}/auth/register/admin`,
    data
  )
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
}
