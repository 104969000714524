import React from "react";
import {hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import {appToastMessages, toastOptions} from "../../../config/Constants";

export default function Step1({type, setType, setMode, mode, goNextStep}) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Notification mode*</label>
                            <select
                                required
                                defaultValue={mode}
                                className="form-control form-control-md"
                                onChange={(name) => {
                                    setMode((name).target.value)
                                }}
                            >
                                <option value={"test"}>{"Test"}</option>
                                <option value={"production"}>{"Production"}</option>
                            </select>
                        </div>

                        <div className="form-group mb-3">
                            <label className="text-label">Notification type*</label>
                            <select
                                required
                                defaultValue={type}
                                className="form-control form-control-md"
                                onChange={(name) => {
                                    setType((name).target.value)
                                }}
                            >
                                <option value={"url"}>{"Url"}</option>
                                <option value={"instore"}>{"In store"}</option>
                                <option value={"online"}>{"Online"}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-primary sw-btn-next"
                        onClick={() => goNext()}>Next
                </button>
            </div>
        </>
    );

    function goNext(){
        if(hasAValue(mode) && hasAValue(type)){
            goNextStep()
        }else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions)
        }
    }
};
