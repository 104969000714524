// KWMModal.js
import React from "react";
import Modal from "./Modal";
import { EndKWM } from "../constant/modals";

const KWMModal = ({ id, ModalTrigger,userData }) => {
  return (
    <Modal
      id={id}
      ModalTitle="Do you really want to end KWM for user?"
      ModalBody={<EndKWM />}
      Theme="KWM"
      endpoint="update_membership_cancellation_active_reminder_admin"
      ModalTrigger={ModalTrigger}
      userData={userData}
    />
  );
};

export default KWMModal;
