import React, {useEffect, useState} from 'react';

import {useParams} from "react-router-dom";
import {checkOneTimeAuthTokenCall, updatePasswordCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import logo from "../../../style/images/knaek/1024x1024.png";


export default function ForgotPassword() {
    const {token} = useParams();
    const [newPassword, setNewPassword] = useState(undefined);
    const [newPassword2, setNewPassword2] = useState(undefined);
    const [canChange, setCanChange] = useState(false);

    useEffect(() => {
        checkOneTimeAuthToken()
    }, []);

    return (
        <>
            <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-aside text-center d-flex justify-content-center flex-column flex-row-auto">
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                        <div className="text-center mb-4">
                            <img src={logo} className={"rounded"} alt="" width={150}/>
                        </div>
                        <h3 className="mb-2">Welcome back!</h3>
                    </div>

                </div>
                <div
                    className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <section className="section align-items-center justify-content-center mt-5">
                            <div className="container mt-5">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 bg-white">

                                        <div className={"text-left mt-5 mb-3 bg-gray p-5 radius-25"}>
                                            <h4 className="my-4">Enter your new password</h4>

                                            <li className="list-group-item radius-15 contact-form-fields-div border-0 my-4">
                                                <label>New Password</label>
                                                <input name="first" type="password"
                                                       className="form-control bg-transparent shadow-none"
                                                       onChange={(val) => {
                                                           setNewPassword(val.target.value);
                                                           setCanChange(val.target.value.length > 8 && val.target.value === newPassword2);
                                                       }}
                                                />
                                            </li>

                                            <li className="list-group-item radius-15 contact-form-fields-div border-0 my-2">
                                                <label>Confirm your password</label>
                                                <input name="second" type="password"
                                                       className="form-control bg-transparent shadow-none"
                                                       onChange={(val) => {
                                                           setNewPassword2(val.target.value);
                                                           setCanChange(val.target.value.length > 8 && val.target.value === newPassword);
                                                       }}
                                                />
                                            </li>

                                            <div className="text-center form-group mb-3">
                                                <button className="btn btn-primary btn-block" disabled={!canChange}
                                                        onClick={() => updatePassword()}>
                                                    Change Password
                                                </button>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );

    async function checkOneTimeAuthToken() {
        try {
            let response = await checkOneTimeAuthTokenCall({token});
            if (!response?.valid) {
                toast.error('The link is expired!', toastOptions);
                setTimeout(() => {
                    window.location.href = "/page-login"
                }, 3000);
            }
        } catch (e) {
            toast.error('The link is expired!', toastOptions);
            setTimeout(() => {
                window.location.href = "/page-login"
            }, 3000);
        }
    }


    async function updatePassword() {
        if (token && canChange) {
            const data = {token, password: newPassword, password2: newPassword2};
            try {
                await updatePasswordCall(data);
                toast.success('Password has been changed. You can now sign in with your new password.', toastOptions);
                setTimeout(() => {
                    window.location.href = "/page-login";
                }, 4000);
            } catch (e) {
                toast.error(e.response?.data?.payload?.message?.[0] ?? e.message, toastOptions);
                setTimeout(() => {
                    window.location.href = "/page-login";
                }, 3000);
            }
        } else {
            toast.error('The link is expired!', toastOptions);
        }
    }

}
