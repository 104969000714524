import { hasAValue } from "../../utils/SharedFunctions";

export const columns_user = [
//   {
//     Header: "Id",
//     Footer: "Id",
//     accessor: "id",
//     Cell: ({ value }) => {
//       return hasAValue(value) ? value : "-";
//     },
//   },
  {
    Header: "First Name",
    Footer: "First Name",
    accessor: "firstName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Last Name",
    Footer: "Last Name",
    accessor: "lastName",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Email",
    Footer: "Email",
    accessor: "email",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
  {
    Header: "Phone number",
    Footer: "Phone number",
    accessor: "phoneNumber",
    Cell: ({ value }) => {
      return hasAValue(value) ? value : "-";
    },
  },
];
