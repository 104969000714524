import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const center = [50.7749, -122.4194];



const LocationsMap = ({data}) => {
    // console.log(data)
    // const locations = data.map(item=> [item.latitude, item.longitude ])

    return (
        <MapContainer center={center} zoom={10} style={{ width: '100%', height: '500px' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {data.map((location, index) => (
                <Marker key={index} position={[location.latitude, location.longitude]} >
                    <Popup>Name: {location.name}
                        <br />Address: {location.address}
                        <br />City: {location.city}
                        <br />Latitude: {location.latitude}
                        <br />Longitude:{location.longitude}.</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default LocationsMap;
