import React, { useEffect, useState } from "react";
import Section from "./common/Section";
import DropdownSection from "./common/DropDownSection";
import Divider from "./common/Divider";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import swal from "sweetalert";

import {
  accountDataLabel,
  appLabel,
  labelMappings,
  personalDataLabels,
  studyLabels,
  subscriptionLabel,
} from "./constant";
import Button from "./common/Button";
import { extractData } from "./helpers";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { handleError } from "../../../utils/SharedFunctions";
import { getUserDetailsById, updateUser } from "../../../Api/ApiCalls";
import KWMModal from "./modals/KwmModal";
import EraseUserModal from "./modals/EraseUserModal";
import CancelSubscriptionModal from "./modals/CancelSubscritionModal";
import { Modal } from "react-bootstrap";
import EntityModal from "./modals/EntityModal";
import Entity from "./common/Entity";

const UserInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMembershipCancelled, setIsMembershipCancelled] = useState(false);
  const [modifiedData, setModifiedData] = useState({});
  const [isCrucialDataModified, setIsCrucialDataModified] = useState(false);
  const [entities, setEntities] = useState({
    personalData: [],
    study: [],
    app: [],
    account: [],
    subscription: [],
  });
  const { userId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getUserDetailsById(userId)
      .then((res) => {
        setUserData(res);
        setIsLoading(false);
      })
      .catch((err) => handleError(err));
  }, [userId]);

  useEffect(() => {
    if (userData) {
      const updatedEntities = {
        personalData: personalDataLabels.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        study: studyLabels.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        app: appLabel.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        account: accountDataLabel.map((label) => ({
          label: label,
          data: extractData(label, labelMappings, userData),
        })),
        subscription: userData?.subscriptions
          .map((subscription, subscriptionIndex) =>
            subscriptionLabel.map((label) => ({
              label: label,
              data: extractData(label, labelMappings, subscription),
              subscriptionIndex: subscriptionIndex + 1,
            }))
          )
          .flat(),
      };
      setEntities(updatedEntities);
    }
  }, [userData]);

  const handleEntityUpdate = (label, newData) => {
    let changedEntity;
    let isCriticalData = false;
    const criticalLabels = [
      "Account owner",
      "Channel",
      "Mandate number",
      "Incasso reference",
      "Bank account name",
      "IBAN",
      "BIC",
      "Credit Card",
    ];

    setEntities((prevEntities) => {
      const updatedEntities = { ...prevEntities };
      const entityToUpdate = Object.keys(updatedEntities).find((key) =>
        updatedEntities[key].some((entity) => entity.label === label)
      );

      updatedEntities[entityToUpdate] = updatedEntities[entityToUpdate].map(
        (entity) => {
          if (entity.label === label && entity.data !== newData) {
            if (label === "Knaek-ID") {
              changedEntity = {
                knaekCode: {
                  id: newData,
                  expirationDate: prevEntities.app[2].data,
                },
              };
            } else if (label === "Knaek-ID expiration date") {
              changedEntity = {
                knaekCode: {
                  id: prevEntities.app[1].data,
                  expirationDate: newData,
                },
              };
            } else {
              changedEntity = { [labelMappings[label]]: newData };
              if (criticalLabels.includes(label)) {
                setIsCrucialDataModified(true);
              }
            }
            return { ...entity, data: newData };
          } else {
            return entity;
          }
        }
      );
      return updatedEntities;
    });
    setModifiedData((prev) => ({
      ...prev,
      ...changedEntity,
    }));
    return changedEntity;
  };

  const handleUpdateUser = async () => {
    if (Object.keys(modifiedData).length === 0) {
      toast.error("Nothing is changed");
      return;
    }

    const confirmUpdate = async () => {
      try {
        await updateUser(modifiedData,userData?.id);
        setShowModal(false)
        Swal.close();
        toast.success("User updated successfully");
      } catch (err) {
        Swal.close();
        toast.error(err.response.data.message);
      }
    };

    if (isCrucialDataModified) {
      const shouldDelete = await swal({
        title: "Are you sure?",
        text: "You want to update this crucial data for this user!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (shouldDelete) {
        await confirmUpdate();
      }
    } else {
      await confirmUpdate();
    }
  };

  useEffect(() => {
    if (userData?.subscriptions) {
      const hasCancelled = JSON.stringify(userData?.subscriptions).includes(
        "CANCELLED"
      );
      setIsMembershipCancelled(hasCancelled);
    } else if (
      userData?.subscriptions &&
      userData?.subscriptions?.length <= 0
    ) {
      setIsMembershipCancelled(true);
    }
  }, [userData]);

  function openModalEdit() {
    setShowModal(true);
    setShowEdit(true);
  }

  const generateButtonWithModals = () => {
    return (
      <>
        <KWMModal
          id="1"
          ModalTrigger={
            <Button
              text="End KWM for user"
              variant="green"
              data-toggle="modal"
            />
          }
          userData={userData}
        />
        <CancelSubscriptionModal
          id="3"
          ModalTrigger={
            <Button
              disabled={isMembershipCancelled}
              className={"disabled"}
              text={`Cancel Subscription`}
              variant="red"
              data-toggle="modal"
            />
          }
          userData={userData}
        />
        <Button
          text="Whitelist User"
          variant="yellow"
          data-toggle="modal"
          onClick={() => {
            openModalEdit(userData);
          }}
        />
        <Button
          text="Edit User"
          variant="pink"
          data-toggle="modal"
          onClick={() => {
            openModalEdit(userData);
          }}
        />
        <EraseUserModal
          id="2"
          ModalTrigger={
            <Button
              text="Erase User"
              variant="cyan"
              data-toggle="modal"
              disabled={true}
            />
          }
          userData={userData}
        />{" "}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="spinner-border text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="row justify-content-center">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header">
                  <h1 className="card-title text-primary" style={{fontSize:"25px"}}>User Details</h1>
                  <div className="d-flex gap-2">
                    <Button text="Mandate" variant="darkGray" />
                    {generateButtonWithModals()}
                  </div>
                </div>
                <div className="card-body">
                  <Section
                    title="Personal data"
                    entities={entities.personalData}
                    onUpdate={handleEntityUpdate}
                  />
                  <Divider />
                  <div className="d-flex">
                    <div className="w-50">
                      <div className="h2 font-weight-bold col-3 text-primary">
                        Study
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {entities?.study?.map((entity, index) => (
                          <Entity
                            key={index}
                            label={entity.label}
                            data={entity.data}
                            onUpdate={handleEntityUpdate}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="w-50">
                      <div className="h2 font-weight-bold  col-3 text-primary">
                        App
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                      >
                        {entities?.app.map((entity, index) => (
                          <Entity
                            key={index}
                            label={entity.label}
                            data={entity.data}
                            onUpdate={handleEntityUpdate}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <Divider />{" "}
                  <Section
                    title="Account Data"
                    entities={entities?.account}
                    onUpdate={handleEntityUpdate}
                  />
                  <Divider />{" "}
                  {userData?.subscriptions?.map((_, index) => (
                    <DropdownSection
                      key={index}
                      title={`Subscription ${
                        userData?.subscriptions.length - index
                      }`}
                      entities={entities?.subscription?.filter(
                        (entity) => entity.subscriptionIndex === index + 1
                      )}
                      onUpdate={handleEntityUpdate}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal fade"
            size={"xl"}
            show={showModal}
            onHide={() => setShowModal(false)}
          >
            <div className="" role="document">
              <div className="">
                <form>
                  <div className="modal-header">
                    <h4 className="modal-title fs-20">Update User</h4>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowModal(false)}
                      data-dismiss="modal"
                    ></button>
                  </div>
                  <EntityModal
                    userData={userData}
                    handleEntityUpdate={handleEntityUpdate}
                  />
                  {showEdit && (
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleUpdateUser}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="btn btn-warning"
                      >
                        <i className="flaticon-delete-1"></i> Ignore changes
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default UserInfo;
