import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';

import {orders_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {getAllOrdersCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import {toastOptions} from "../../../config/Constants";
import { handleError } from '../../../utils/SharedFunctions';


export default function Orders() {
	const [data, setData] = useState([])
	const [columns, setColumns] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showEdit, setShowEdit] = useState(false)


	const [id, setId] = useState(undefined)
	const [first_name, setFirst_name] = useState(undefined)
	const [last_name, setLast_name] = useState(undefined)
	const [email, setEmail] = useState(undefined)
	const [student_email, setStudent_email] = useState(undefined)
	const [origin, setOrigin] = useState(undefined)
	const [country_code, setCountry_code] = useState(undefined)
	const [phone_number, setPhone_number] = useState(undefined)
	const [status, setStatus] = useState(undefined)
	const [chanel, setChanel] = useState(undefined)
	const [country, setCountry] = useState(undefined)
	const [mollie_payment_id, setMollie_payment_id] = useState(undefined)
	const [mollie_payment_link, setMollie_payment_link] = useState(undefined)
	const [intro_name, setIntro_name] = useState(undefined)


	const [id_item, setId_item] = useState(undefined)


	useEffect(() => {
		setColumns(orders_col)
		getAllOrders()
	}, [])


	const tableInstance = useTable({
		columns,
		data,
		initialState: {pageIndex: 0}
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {globalFilter, pageIndex} = state


	return (
		<>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Orders</h4>
					{/*<a className="btn btn-secondary" onClick={() => openModalNew()}>+ Location toevoegen</a>*/}

				</div>
				<div className="card-body">
					<div className="table-responsive">
						<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
						<table {...getTableProps()} className="table dataTable display">
							<thead>
							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											<span className="ml-1">
                                                {column.isSorted ? (
														column.isSortedDesc ?
															<i className="fa fa-arrow-down ms-2 fs-14"
															   style={{opacity: '0.7'}}/>
															:
															<i className="fa fa-arrow-up ms-2 fs-14"
															   style={{opacity: '0.7'}}/>
													)
													:
													(<i className="fa fa-sort ms-2 fs-14"
														style={{opacity: '0.3'}}/>)}
                                            </span>
										</th>
									))}
								</tr>
							))}
							</thead>
							<tbody {...getTableBodyProps()}>

							{page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()} className={"cursor-pointer"}
													   onClick={() => openModalEdit(row.original)}
											> {cell.render('Cell')} </td>
										})}

									</tr>
								)
							})}
							</tbody>
						</table>
						{/* This is only for footer if u require */}
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number"
									   className="ml-2"
									   defaultValue={pageIndex + 1}
									   onChange={e => {
										   const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										   gotoPage(pageNumber)
									   }}
								/>
							</span>
						</div>
						<div className="text-center mb-3">
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)}
										disabled={!canPreviousPage}>{'<<'}</button>

								<button className="previous-button" onClick={() => previousPage()}
										disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
										disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
				<div className="" role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Order</h4>
								<button type="button" className="btn-close" onClick={() => setShowModal(false)}
										data-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className={"row"}>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">User ID</label>
												<div className="contact-name">
													<span className="validation-text">{id}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">First name</label>
												<div className="contact-name">
													<span className="validation-text">{first_name}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Last name</label>
												<div className="contact-name">
													<span className="validation-text">{last_name}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<span className="validation-text">{email}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Student email</label>
												<div className="contact-name">
													<span className="validation-text">{student_email}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Origin</label>
												<div className="contact-name">
													<span className="validation-text">{origin}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Student email</label>
												<div className="contact-name">
													<span className="validation-text">{student_email}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Country code</label>
												<div className="contact-name">
													<span className="validation-text">{country_code}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Phone number</label>
												<div className="contact-name">
													<span className="validation-text">{phone_number}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Status</label>
												<div className="contact-name">
													<span className="validation-text">{status}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Chanel</label>
												<div className="contact-name">
													<span className="validation-text">{chanel}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Country</label>
												<div className="contact-name">
													<span className="validation-text">{country}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Mollie Payment-ID</label>
												<div className="contact-name">
													<span className="validation-text">{mollie_payment_id}</span>
												</div>
											</div>


											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Mollie Payment Link</label>
												<div className="contact-name">
													<span className="validation-text text-break">{mollie_payment_link}</span>
												</div>
											</div>

											<hr />

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Intro name</label>
												<div className="contact-name">
													<span className="validation-text">{intro_name}</span>
												</div>
											</div>


										</div>

									</div>
								</div>
							</div>
							{/*{!showEdit && <div className="modal-footer">*/}

							{/*    <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i*/}
							{/*        className="flaticon-delete-1"></i> Close*/}
							{/*    </button>*/}
							{/*    <button type="button" className="btn btn-secondary"*/}
							{/*            onClick={() => addContactPersonAdmin()}>Toevoegen*/}
							{/*    </button>*/}
							{/*</div>}*/}

							{showEdit && <div className="modal-footer">
								{/*<button type="button" className="btn btn-secondary"*/}
								{/*		onClick={() => updateAction()}>Edit</button>*/}
								<button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
									className="flaticon-delete-1"></i> Close
								</button>
							</div>}
						</form>

					</div>
				</div>
			</Modal>
		</>
	)


	function openModalEdit(item) {
		setId(item.id)
		setFirst_name(item.first_name)
		setLast_name(item.last_name)
		setEmail(item.email)
		setStudent_email(item.student_email)
		setOrigin(item.origin)
		setCountry_code(item.country_code)
		setPhone_number(item.phone_number)
		setStatus(item.status)
		setChanel(item.chanel)
		setCountry(item.country)
		setMollie_payment_id(item.mollie_payment_id)
		setMollie_payment_link(item.mollie_payment_link)
		setIntro_name(item.intro_name)


		setId_item(item.id)
		setShowModal(true)
		setShowEdit(true)
	}

	function getAllOrders(){
		getAllOrdersCall().then(r => {
			setData(r)
		}).catch(error=>{
            handleError(error)
		})
	}

	// function updateAction(){
	// 	let data = {id: locationId, latitude,longitude }
	// 	updateRatingActionCall(data).then(success => {
	// 		getAllLocations()
	// 		toast.success(success.data.message, toastOptions);
	// 	}).catch(error=>{
	// 		toast.error(error.response.data.error, toastOptions);
	// 	})
	// }
}
