import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';

import {locations_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {
	fetchAllLocationsCall, updateGeoLocationCall,
	updateRatingActionCall
} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import {toastOptions} from "../../../config/Constants";
import LocationsMap from "../../../components/Maps/LocationsMap";
import { handleError } from '../../../utils/SharedFunctions';


export default function Locations() {
	const [data, setData] = useState([])
	const [columns, setColumns] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showEdit, setShowEdit] = useState(false)


	const [locationId, setLocationId] = useState(undefined)
	const [companyId, setCompanyId] = useState(undefined)
	const [name, setName] = useState(undefined)
	const [address, setAddress] = useState(undefined)
	const [city, setCity] = useState(undefined)
	const [latitude, setLatitude] = useState(undefined)
	const [longitude, setLongitude] = useState(undefined)



	const [id_item, setId_item] = useState(undefined)


	useEffect(() => {
		setColumns(locations_col)
		getAllLocations()
	}, [])


	const tableInstance = useTable({
		columns,
		data,
		initialState: {pageIndex: 0}
	}, useFilters, useGlobalFilter, useSortBy, usePagination)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = tableInstance


	const {globalFilter, pageIndex} = state


	return (
		<>
			<div className="card">
				<div className="card-header">
					<h4 className="card-title">Locations</h4>
					{/*<a className="btn btn-secondary" onClick={() => openModalNew()}>+ Location toevoegen</a>*/}

				</div>
				<div className="card-body">
					<LocationsMap data={data} />
					<div className="table-responsive">
						<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
						<table {...getTableProps()} className="table dataTable display">
							<thead>
							{headerGroups.map(headerGroup => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<th {...column.getHeaderProps(column.getSortByToggleProps())}>
											{column.render('Header')}
											<span className="ml-1">
                                                {column.isSorted ? (
														column.isSortedDesc ?
															<i className="fa fa-arrow-down ms-2 fs-14"
															   style={{opacity: '0.7'}}/>
															:
															<i className="fa fa-arrow-up ms-2 fs-14"
															   style={{opacity: '0.7'}}/>
													)
													:
													(<i className="fa fa-sort ms-2 fs-14"
														style={{opacity: '0.3'}}/>)}
                                            </span>
										</th>
									))}
								</tr>
							))}
							</thead>
							<tbody {...getTableBodyProps()}>

							{page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()} className={"cursor-pointer"}
													   onClick={() => openModalEdit(row.original)}
											> {cell.render('Cell')} </td>
										})}

									</tr>
								)
							})}
							</tbody>
						</table>
						{/* This is only for footer if u require */}
						<div className="d-flex justify-content-between">
							<span>
								Page{' '}
								<strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
							<span className="table-index">
								Go to page : {' '}
								<input type="number"
									   className="ml-2"
									   defaultValue={pageIndex + 1}
									   onChange={e => {
										   const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
										   gotoPage(pageNumber)
									   }}
								/>
							</span>
						</div>
						<div className="text-center mb-3">
							<div className="filter-pagination  mt-3">
								<button className=" previous-button" onClick={() => gotoPage(0)}
										disabled={!canPreviousPage}>{'<<'}</button>

								<button className="previous-button" onClick={() => previousPage()}
										disabled={!canPreviousPage}>
									Previous
								</button>
								<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
									Next
								</button>
								<button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
										disabled={!canNextPage}>{'>>'}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
				<div className="" role="document">
					<div className="">
						<form>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Location</h4>
								<button type="button" className="btn-close" onClick={() => setShowModal(false)}
										data-dismiss="modal"></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className={"row"}>
											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Location Id</label>
												<div className="contact-name">
													<span className="validation-text">{locationId}</span>
												</div>
											</div>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Company Id</label>
												<div className="contact-name">
													<span className="validation-text">{companyId}</span>
												</div>
											</div>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Company name</label>
												<div className="contact-name">
													<span className="validation-text">{name}</span>
												</div>
											</div>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Address</label>
												<div className="contact-name">
													<span className="validation-text">{address}</span>
												</div>
											</div>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">City</label>
												<div className="contact-name">
													<span className="validation-text">{city}</span>
												</div>
											</div>

											<hr/>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Latitude</label>
												<div className="contact-name">
													<input type="text" className="form-control"
														   name="name" required="required"
														   defaultValue={latitude}
														   onChange={(val) => setLatitude(val.target.value)}
													/>
													<span className="validation-text"></span>
												</div>
											</div>

											<div className="form-group mb-3 col-sm-10 col-md-6">
												<label className="text-black font-w500">Longitude</label>
												<div className="contact-name">
													<input type="text" className="form-control"
														   name="name" required="required"
														   defaultValue={longitude}
														   onChange={(val) => setLongitude(val.target.value)}
													/>
													<span className="validation-text"></span>
												</div>
											</div>


										</div>

									</div>
								</div>
							</div>
							{/*{!showEdit && <div className="modal-footer">*/}

							{/*    <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i*/}
							{/*        className="flaticon-delete-1"></i> Close*/}
							{/*    </button>*/}
							{/*    <button type="button" className="btn btn-secondary"*/}
							{/*            onClick={() => addContactPersonAdmin()}>Toevoegen*/}
							{/*    </button>*/}
							{/*</div>}*/}

							{showEdit && <div className="modal-footer">
								<button type="button" className="btn btn-secondary"
										onClick={() => updateAction()}>Edit</button>
								<button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
									className="flaticon-delete-1"></i> Close
								</button>
							</div>}
						</form>

					</div>
				</div>
			</Modal>
		</>
	)


	function openModalEdit(item) {
		setLocationId(item.locationId)
		setCompanyId(item.companyId)
		setName(item.name)
		setAddress(item.address)
		setCity(item.city)
		setLatitude(item.latitude)
		setLongitude(item.longitude)


		setId_item(item.id)
		setShowModal(true)
		setShowEdit(true)
	}

	function getAllLocations(){
		fetchAllLocationsCall().then(r => {
			setData(r)
		}).catch(error=>{
            handleError(error)
		})
	}

	function updateAction(){
		let data = {}
		data['id'] = locationId
		data['latitude'] = parseFloat(latitude)
		data['longitude'] = parseFloat(longitude)
		console.log(data)
		updateGeoLocationCall(data).then(success => {
			toast.success(success.message, toastOptions);
			getAllLocations()
		}).catch(error=>{
            handleError(error)
		})
	}
}
