import React, {useEffect, useState} from "react";
import Modal from "./Modal";
import { CancelSubscription } from "../constant/modals";
import {getDateOver} from "../../../../utils/SharedFunctions";

const CancelSubscriptionModal = ({ id, ModalTrigger, userData }) => {
  const [cancelationData, setCancelationData] = useState({
    date: "",
    checked: false,
  });

  return (
    <Modal
      id={id}
      ModalTitle="Your Cancel Subscription Modal Title"
      ModalBody={
        <CancelSubscription
          inputLabel="Cancelation date"
          inputOnChange={(e) =>
            setCancelationData({ ...cancelationData, date: e.target.value })
          }
          inputValue={cancelationData.date}
          inputPlaceholder="02-05-2024"
          noPaymentLink={cancelationData.checked}
          checkboxOnChange={() => {
            setCancelationData({
              ...cancelationData,
              checked: !cancelationData.checked,
            });
          }}
        />
      }
      Theme="cancel-sub"
      endpoint="unsubscribe_member_admin"
      userData={userData}
      cancelationData={cancelationData}
      disabled={
        userData?.subscriptions?.[userData?.subscriptions?.length - 1]
          ?.status === "CANCELLED"
      }
      ModalTrigger={ModalTrigger}
    />
  );
};

export default CancelSubscriptionModal;
