import React, {useEffect, useState} from "react";
import Select from "react-select";
import {fetchFilterProductionNotificationCall, fetchTestUsersNotificationCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {hasAValue} from "../../../utils/SharedFunctions";

export default function Step3({
                                  setUserListForTestMode,
                                  userListForTestMode,
                                  setFilterSession,
                                  setFilterLimited, setFilterGender,
                                  setSelectedFiltersCities,
                                  selectedFiltersCities,
                                  mode,
                                  goBack,
                                  goNextStep,
                                  androidTargetDevice,
                                  iosTargetDevice,
                                  setIosTargetDevice,
                                  setAndroidTargetDevice,
                                  fake, setFake,
                                  country, setCountry,
                                  filterLimited, filterGender, filterSession
                              }) {
    const [productionFilters, setProductionFilters] = useState([])
    const [productionFiltersCities, setProductionFiltersCities] = useState([])
    const [productionFiltersLimited, setProductionFiltersLimited] = useState([])
    const [productionFiltersGender, setProductionFiltersGender] = useState([])
    const [productionFiltersSession, setProductionFiltersSession] = useState([])
    const [testUsers, setTestUsers] = useState([])


    useEffect(() => {
        fetchFilterProductionNotification()
    }, [])


    useEffect(() => {
        fetchTestUsersNotification()
    }, [])


    useEffect(() => {
        if (hasAValue(productionFilters.cities)) {
            let cities = productionFilters.cities.filter(item => parseInt(item.country_id) === parseInt(country))
            setProductionFiltersCities(cities)
        }

    }, [country])


    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText/>,
            getStyles,
            innerProps: {ref, ...restInnerProps},
        } = props;
        return (
            <div {...restInnerProps}
                 ref={ref}
                 style={getStyles("clearIndicator", props)}
            >
                <div style={{padding: "0px 5px"}}>{children}</div>
            </div>
        );
    };

    const ClearIndicatorStyles = (base, state) => ({
        ...base,
        cursor: "pointer",
        color: state.isFocused ? "blue" : "black",
    });


    return (
        <>
            {/*Test*/}
            {mode.includes("test") &&
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Target users*</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ClearIndicator}}
                                styles={{clearIndicator: ClearIndicatorStyles}}
                                isMulti
                                defaultValue={userListForTestMode}
                                required
                                options={testUsers}
                                onChange={(val) => {
                                    setUserListForTestMode(val)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>}

            {/*Production*/}
            {mode.includes("production") && <section>
                <div className="row justify-content-center">
                    <div className="col-lg-12 mb-2">
                        <div className="form-group">
                            <label className="text-label">Target groups*</label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-start">
                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Country</label>
                            <select
                                className="form-control form-control-md"
                                defaultValue={hasAValue(country) && country}
                                required
                                onChange={(val) => {
                                    setCountry(val.target.value)
                                }}
                            >
                                <option value={1}>Netherlands</option>
                                <option value={2}>Belgium</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Cities</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={{ClearIndicator}}
                                styles={{clearIndicator: ClearIndicatorStyles}}
                                isMulti
                                defaultValue={selectedFiltersCities}
                                required
                                options={productionFiltersCities}
                                onChange={(val) => {
                                    setSelectedFiltersCities(val)
                                }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Limited</label>
                            <select
                                className="form-control form-control-md"
                                value={filterLimited}
                                required
                                onChange={(val) => {
                                    setFilterLimited(val.target.value)
                                }}
                            >
                                <option value={'null'}>Choose an option</option>

                                {productionFiltersLimited.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Gender</label>
                            <select
                                className="form-control form-control-md"
                                value={filterGender}
                                required
                                onChange={(val) => {
                                    setFilterGender(val.target.value)
                                }}
                            >
                                <option value={'null'}>Choose an option</option>

                                {productionFiltersGender.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-10 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Session (Not working yet)</label>
                            <select
                                className="form-control form-control-md"
                                value={filterSession}
                                required
                                onChange={(val) => {
                                    setFilterSession(val.target.value)
                                }}
                            >
                                <option value={'null'}>Choose an option</option>

                                {productionFiltersSession.map((item, i)=>{
                                    return(
                                        <option value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>




                </div>
            </section>}
            <div className="col-md-6 col-sm-10 mb-2 d-flex align-items-center align-content-center ">
                <div className="row w-100 pt-3">
                    <div className={"col-6 w-100"}>
                        <div className="row ">
                            <div className="col-md-4 mb-2">
                                <div className="form-group">
                                    <label className="text-label me-3">Android devices</label>

                                    <input
                                        type="checkbox"
                                        defaultValue={androidTargetDevice}
                                        checked={androidTargetDevice}
                                        className="form-check-input"
                                        onChange={() => setAndroidTargetDevice(!androidTargetDevice)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group">
                                    <label className="text-label me-3">iOS devices</label>
                                    <input
                                        type="checkbox"
                                        defaultValue={iosTargetDevice}
                                        checked={iosTargetDevice}
                                        className="form-check-input"
                                        onChange={() => setIosTargetDevice(!iosTargetDevice)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="form-group">
                                    <label className="text-label me-3">Is it for tests goals?</label>
                                    <input
                                        type="checkbox"
                                        defaultValue={fake}
                                        checked={fake}
                                        className="form-check-input"
                                        onChange={() => setFake(!fake)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section>


            </section>

            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => goBack()}>Prev
                </button>
                <button className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => goNext()}>Next
                </button>
            </div>
        </>
    );

    function goNext() {
        if (mode.includes("test")) {
            if (userListForTestMode.length > 0) {
                goNextStep()
            } else {
                toast.error(appToastMessages.all_fields_are_required, toastOptions)
            }
        } else {
            if (hasAValue(selectedFiltersCities) && selectedFiltersCities.length > 0) {
                goNextStep()
            } else {
                toast.error(appToastMessages.all_fields_are_required, toastOptions)
            }
        }

    }

    function fetchFilterProductionNotification() {
        fetchFilterProductionNotificationCall().then(results => {
            setProductionFilters(results)
            setProductionFiltersLimited(results.usersLimitedFilters)
            setProductionFiltersGender(results.usersGenderFilters)
            setProductionFiltersSession(results.usersSessionFilters)
            let cities = results.cities.filter(item => parseInt(item.country_id) === parseInt(country))
            setProductionFiltersCities(cities)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


    function fetchTestUsersNotification() {
        fetchTestUsersNotificationCall().then(results => {
            setTestUsers(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


};
