import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';

//import './table.css';
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import swal from "sweetalert";
import {actions_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {fetchAllActions, fetchAllActionsCall, updateRatingActionCall} from "../../../Api/ApiCalls";
import {toastOptions} from "../../../config/Constants";
import { handleError } from '../../../utils/SharedFunctions';


export default function ActionsOverview() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)


    const [actionId, setActionId] = useState(undefined)
    const [average_saving, setAverage_saving] = useState(undefined)
    const [companyName, setCompanyName] = useState(undefined)
    const [limited, setLimited] = useState(undefined)
    const [rating, setRating] = useState(undefined)
    const [title, setTitle] = useState(undefined)
    const [subtitle, setSubtitle] = useState(undefined)



    const [id_item, setId_item] = useState(undefined)


    useEffect(() => {
        setColumns(actions_col)
        getAllActions()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Actions</h4>
                    {/*<a className="btn btn-secondary" onClick={() => openModalNew()}>+ Website toevoegen</a>*/}

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalEdit(row.original)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Action</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Title</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={title}
                                                           onChange={(val) => setTitle(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Subtitle</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={subtitle}
                                                           onChange={(val) => setSubtitle(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Company name</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={companyName}
                                                           onChange={(val) => setCompanyName(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Rating</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={rating}
                                                           onChange={(val) => setRating(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/*{!showEdit && <div className="modal-footer">*/}

                            {/*    <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i*/}
                            {/*        className="flaticon-delete-1"></i> Close*/}
                            {/*    </button>*/}
                            {/*    <button type="button" className="btn btn-secondary"*/}
                            {/*            onClick={() => addContactPersonAdmin()}>Toevoegen*/}
                            {/*    </button>*/}
                            {/*</div>}*/}

                            {showEdit && <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => updateAction()}>Edit</button>
                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
                                    className="flaticon-delete-1"></i> Close
                                </button>
                            </div>}
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalEdit(item) {
        setActionId(item.actionId)
        setAverage_saving(item.average_saving)
        setRating(item.rating)
        setLimited(item.limited)
        setCompanyName(item.companyName)
        setTitle(item.localization.en.title)
        setSubtitle(item.localization.en.subtitle)


        setId_item(item.id)
        setShowModal(true)
        setShowEdit(true)
    }

    function getAllActions(){
        fetchAllActionsCall().then(r => {
            console.log(r)
            setData(r)
        }).catch(error=>{
            handleError(error)
        })
    }

    function updateAction(){
        let data = {id: actionId, rating}
        updateRatingActionCall(data).then(success => {
            getAllActions()
            toast.success(success.data.message, toastOptions);
        }).catch(error=>{
            handleError(error)
        })
    }
}
