import axios from "axios";
import {generateSignature} from "../utils/SharedFunctions";
import {store} from "../redux/store";
import {refreshTokenCall} from "./ApiCalls";
import {updateUserData} from "../redux/actions/storeActions";
import {toast} from "react-toastify";

let retryCount = 0;
const maxRetries = 5;
async function apiCallWrapper(apiCallFunction) {
    const stateRedux = store.getState();
    let accessToken = stateRedux.userData?.accessToken;
    const refreshToken = stateRedux.userData?.refreshToken;

    if(retryCount === 5 && stateRedux.userData){
        console.log(retryCount)
        retryCount = 0
    }

    while (retryCount < maxRetries) {
        try {
            retryCount++; // Ensure this increment happens correctly

            // Await is crucial here to ensure the delay is applied
            // await new Promise(resolve => setTimeout(resolve, 2000));
            // console.log(retryCount)

            const res = await apiCallFunction(accessToken);
            return res.data;
        } catch (e) {
            if (e.response?.status === 401) {

                if (retryCount >= maxRetries) {
                    console.log('Maximum token refresh attempts reached. Unable to authenticate.');
                    throw new Error('Authentication failed after maximum retries.');
                }

                try {

                    const response = await refreshTokenCall({refreshToken: refreshToken});
                    if (response && response.accessToken) {
                        console.log('Token has been refreshed');
                        store.dispatch(updateUserData({
                            accessToken: response.accessToken,
                            refreshToken: response.refreshToken,
                        }));
                        accessToken = response.accessToken;
                    } else {
                        console.log('Failed to refresh token on attempt:', retryCount);
                        // If the token refresh fails, consider how you want to handle this.
                        // For now, let's throw an error to break out of the loop.
                        throw new Error('Failed to refresh token');
                    }
                } catch (refreshError) {

                    console.log('Error refreshing token on attempt:', retryCount, refreshError);
                    // If refreshing the token also fails, throw an error to stop further retries.
                    toast.warn("Your session has expired")
                    setTimeout(()=>{
                        store.dispatch(updateUserData(null));
                        window.location = "/page-login"
                    }, 4000)


                    throw new Error('Error during token refresh, stopping retries.');
                }
            } else {
                // If the error is not related to authentication, re-throw it to break out of the loop.
                throw e;
            }
        }
    }
}





export const apiGet = async (route) => {
    return apiCallWrapper(async (accessToken) => {
        const {signature, timestamp} = generateSignature({});
        return await axios.get(route, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    })
};

export const apiDelete = async (route, data = {}) => {
    return apiCallWrapper((accessToken) => {
        const {signature, timestamp} = generateSignature(data);
        return axios.delete(route, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    });
};

export const apiPut = async (route, data) => {
    return apiCallWrapper((accessToken) => {
        const {signature, timestamp} = generateSignature(data);
        return axios.put(route, data, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    });
};

export const apiPost = async (route, data) => {
    return apiCallWrapper((accessToken) => {
        const {signature, timestamp} = generateSignature(data);
        return axios.post(route, data, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    });
};

export const apiPostFile = async (route, data) => {
    return apiCallWrapper((accessToken) => {
        const {signature, timestamp} = generateSignature(data);
        return axios.post(route, data, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'multipart/form-data',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    });
};

export const apiDeleteMethod = async (route) => {
    return apiCallWrapper((accessToken) => {
        const {signature, timestamp} = generateSignature({});
        return axios.delete(route, {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': signature,
                'timestamp': timestamp
            }
        });
    });
};
