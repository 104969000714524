import { toastOptions } from "../config/Constants";
import { toast } from "react-toastify";
import { store } from "../redux/store";
import CryptoJS from "crypto-js";

export function hasAValue(value) {
  if (value !== null && value !== undefined && value !== "") {
    return true;
  } else {
    return false;
  }
}

export function getLanguage() {
  // let language = getDataLocalStorage(appLocalStoargeValues.language)
  let language = undefined;
  if (!hasAValue(language)) {
    return "nl";
  } else {
    return language;
  }
}

export async function userLoggedIn() {
  const stateRedux = store.getState();
  let user_data = stateRedux.userData;
  if (hasAValue(user_data)) {
    return true;
  } else {
    return false;
  }
}

export function loginAgain(error) {
  if (parseInt(error.response.status) === 401) {
    toast.warn("Please login again", toastOptions);
    // window.location.reload();
  }
}

export function oddOrEven(number) {
  if (parseInt(number) % 2 === 0) {
    return "even";
  } else {
    return "odd";
  }
}

export function sortAllEducations(educations) {
  educations.sort(function (a, b) {
    return a.name - b.name;
  });
  return educations;
}

export function cellStyle(value) {
  if (value) {
    return <span className={"text-primary"}>{value}</span>;
  }
}

export function generateSignature(body) {
  let timestamp = new Date().getTime().toString();
  const key = timestamp + process.env.REACT_APP_KNAEK_API_KEY;
  return {
    signature: CryptoJS.HmacSHA256(JSON.stringify(body), key).toString(),
    timestamp: timestamp,
  };
}

export function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function humanDate(date) {
  if (!date) return;
  date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}/${month}/${day}`;
}

export function handleError(error) {
  if (error?.response?.data?.error) {
    toast.error(error?.response?.data?.error);
    return;
  }
  const errors = error?.response?.data?.payload?.message;
  if (Array.isArray(errors)) {
    errors.forEach((errorMessage) => {
      toast.error(errorMessage);
    });
  } else {
    toast.error(errors);
  }
}

export function getDateOver(currentDate) {
  // Split the input date string into components
  const [year, month, day] = currentDate?.split('-').map(num => parseInt(num, 10));

  // Construct a new Date object using local time
  // Note: Months are 0-indexed in JavaScript Date, hence the -1 on month
  const futureDate = new Date(year, month - 1, day);

  // Format year, month, and day with leading zeros if necessary
  const formattedYear = futureDate.getFullYear();
  const formattedMonth = ('0' + (futureDate.getMonth() + 1)).slice(-2);
  const formattedDay = ('0' + futureDate.getDate()).slice(-2);

  // Return the formatted date string and the Date object
  return { dateString: `${formattedYear}-${formattedMonth}-${formattedDay}`, dateRaw: futureDate };
}

