import {apiPost} from "./Api";
import {appUrls} from "../config/Constants";



export async function startSendingANotificationCall(data) {
    return apiPost(appUrls.backend_jobs+"start_notification", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}
