import React from 'react';

const Button = ({ text, className, variant,onClick, disabled }) => {
    const variants = {
        darkGray: {
            backgroundColor: '#333e',
        },
        green: {
            backgroundColor: disabled? '#085212' : '#135846',
        },
        red: {
            backgroundColor: disabled? '#a30b18' : '#be2230',
        },
        cyan: {
            backgroundColor: '#09cadb',
        },
        pink: {
            backgroundColor: '#f53c79',
        },
        yellow: {
            backgroundColor: disabled ? '#8b7b00' : '#b69e00',
        },
    };


    const selectedVariant = variants[variant] || variants.darkGray;

    const buttonStyle = {
        backgroundColor: selectedVariant.backgroundColor,
        color: '#fff',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        border: 'none',
        outline: 'none',
    };

    const buttonClassName = `custom-button ${className || ''}`;

    return (
        <button style={buttonStyle} className={buttonClassName} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};

export default Button;
