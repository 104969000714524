import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useFilters, usePagination} from 'react-table';

//import './table.css';
import './../FilteringTable/filtering.css';
import {columns_notification} from "../FilteringTable/NotificationTabelsColumns";
import {GlobalFilter} from "../FilteringTable/GlobalFilter";
import {fetchAllNotificationsCall, fetchLastUpdateNotificationCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {appUrls, toastOptions} from "../../config/Constants";
import {Modal} from "react-bootstrap";
import {hasAValue} from "../../utils/SharedFunctions";
import {startSendingANotification, startSendingANotificationCall} from "../../Api/ApiCallsJobs";


export default function AllNotification() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationElement, setNotificationElement] = useState(undefined)


    useEffect(() => {
        setColumns(columns_notification)
        fetchAllNotifications()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                            {column.canFilter ? column.render('Filter') : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="">

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalShow(row.original)}> {cell.render('Cell')} </td>

                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Notification</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            {hasAValue(notificationElement) && <div className="modal-body">
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Overview</h2>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>General</strong>

                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Mode</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Type</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Status</strong>
                                                <span className={"col-md-3 col-sm-10 fw-bold text-primary"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.mode}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.type}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.status}</span>

                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Title</strong>
                                                <span className={"col-9"}>{notificationElement.title}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Body</strong>
                                                <span className={"col-9"}>{notificationElement.body}</span>
                                            </li>


                                            {notificationElement.type.toString().includes("url") ?
                                                <li className="list-group-item d-flex px-0 justify-content-between row">
                                                    <strong className={"col-3"}>URL</strong>
                                                    <span
                                                        className={"col-9 fw-bold text-primary"}>{notificationElement.url}</span>
                                                </li> :
                                                <li className="list-group-item d-flex px-0 justify-content-between row">
                                                    <strong className={"col-3"}>Action ID</strong>
                                                    <span
                                                        className={"col-9 fw-bold text-primary"}>{notificationElement.action_id}</span>
                                                </li>}

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Made by</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.made_by}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Created at</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.created_at}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Total receivers</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.total_receivers}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Android</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Total</strong>
                                                <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.firebase_sent}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.firebase_failed}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationElement.firebase_sent) && hasAValue(notificationElement.firebase_failed)) &&
                                                (parseInt(notificationElement.firebase_sent) + parseInt(notificationElement.firebase_failed))}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>iOS</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Total</strong>
                                                <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.ios_sent}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationElement.ios_failed}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationElement.ios_sent) && hasAValue(notificationElement.ios_failed)) &&
                                                (parseInt(notificationElement.ios_sent) + parseInt(notificationElement.ios_failed))}</span>
                                            </li>


                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Fake</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.fake ? "Yes" : "No"}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>iOS target</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.ios_target ? "Yes" : "No"}</span>
                                                <strong className={"col-3"}>Android target</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationElement.android_target ? "Yes" : "No"}</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    startSendingANotification()
                                }}>Start sending notification
                                </button>
                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-warning">Close
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalShow(item) {
        setShowModal(true)
        setNotificationElement(item)
    }

    function startSendingANotification() {
        fetchLastUpdateNotification(notificationElement.id)
        startSendingANotificationCall({id: notificationElement.id}).then(r => {
            toast.success(r.data.message, toastOptions)
        }).catch(error => {
            toast.warn(error.message, toastOptions)
        })


    }

    function fetchLastUpdateNotification(id) {

        let numberOfTimes = 50;
        let delay = 4000;

        for (let i = 0; i < numberOfTimes; i++) {
            setTimeout(() => {
                fetchLastUpdateNotificationCall(id).then(notification => {
                    setNotificationElement(notification)
                }).catch(error => {

                })
                if(numberOfTimes%i === 0){
                    fetchAllNotifications()
                }
            }, delay * i);

        }
    }

    function fetchAllNotifications() {
        fetchAllNotificationsCall().then(results => {
            results.sort(function (a, b) {
                return b.id - a.id
            });
            setData(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions)
        })
    }
}
