export const extractData = (
  label,
  dataMappings,
  userData,
  defaultValue = "-"
) => {
  const mapping = dataMappings[label];


  if ((label === "Commencing date" || label === "Cancellation date") && userData) {
    return transformDate(userData[mapping], false) || defaultValue;
  }
  switch (label) {
    case "Institution": return userData?.education?.institutionName || "-"
    case "Student City": return userData?.cityName || "-"
    case "Education Level": return userData?.education?.eductionLevelName || "-"
    case "Knaek-ID": return userData?.knaekCode?.code || "-"
    case "Knaek-ID expiration date": return transformDate(userData?.knaekCode?.expirationDate, true) || "-"
    case "Credit Card": return userData?.paymentAccountDetails?.creditCardNumber || "-"
    case "BIC": return userData?.paymentAccountDetails?.bic || "-"
    case "Bank account name": return  userData?.paymentAccountDetails?.bankAccountName || "-"
    case "IBAN": return userData?.paymentAccountDetails?.iban || "-"
    case "Channel": return userData?.channel?.parent || "-"
    default:
      return mapping ? userData[mapping] || defaultValue : defaultValue;
  }
};

export const transformDate = (dateString, justDate) => {
  if(!dateString) return  '-'

  if(justDate){
    return new Date(dateString).toLocaleDateString("nl-NL")
  }else{
    return new Date(dateString).toLocaleDateString("nl-NL")+" "+ new Date(dateString).toLocaleTimeString("nl-NL")
  }
};


export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const removeHoursFromDateString = (dateString) => {
  if (dateString?.match(/\d{1,2}:\d{1,2}:\d{1,2}$/)) {
    return dateString?.replace(/\s\d{1,2}:\d{1,2}:\d{1,2}$/, '');
  }
  return dateString;
};