import React from "react";
import Swal from "sweetalert2";
import {
  cancelMembershipForUser,
  deleteUser,
  updateMembershipCancellationActiveReminderAdminCall,
  updateUser,
} from "../../../../Api/ApiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../../../config/Constants";
import {getDateOver} from "../../../../utils/SharedFunctions";

const Modal = ({
  id,
  ModalTitle,
  ModalBody,
  ModalTrigger,
  Theme,
  userData,
  endpoint,
  cancelationData,
  disabled,
  modifiedData
}) => {
  const getButtonConfig = () => {
    switch (Theme) {
      case "KWM":
        return {
          cancelButton: {
            style: "btn btn-secondary",
            text: "End KWM for user",
          },
          saveButton: { style: "btn btn-warning", text: "Close" },
        };
      case "cancel-sub":
        return {
          cancelButton: {
            style: "btn btn-danger",
            text: "Cancel subscription",
          },
          saveButton: { style: "btn btn-warning", text: "Close" },
        };
      case "erase-user":
        return {
          cancelButton: { style: "btn btn-cyan", text: "Erase User" },
          saveButton: { style: "btn btn-warning", text: "Close" },
        };
      case "update_user":
        return {
          saveButton: { style: "btn btn-secondary", text: "Close" },
          cancelButton: { style: "btn btn-warning", text: "Update" },
        };
      default:
        return {
          cancelButton: { style: "btn btn-secondary", text: "Close" },
          saveButton: { style: "btn btn-warning", text: "Save changes" },
        };
    }
  };

  const buttonConfig = getButtonConfig();

  const handleSubmit = async () => {
    startLoading();
    switch (endpoint) {
      case "unsubscribe_member_admin":
        const reformattedDateString = getDateOver(cancelationData.date).dateRaw
        cancelMembershipForUser({
          email: userData.email,
          no_payment_link: cancelationData.checked,
          expiration_date: new Date(reformattedDateString).toISOString(),
        })
          .then((result) => {
            Swal.close();
            toast.success(result.message, toastOptions);
            setTimeout(() => {
              window.open(result.link);
            }, 3000);
          })
          .catch((err) => {
            Swal.close();
            toast.error(err.response.data.message);
          });

        break;
      case "delete_user":
        deleteUser(userData.id)
          .then(() => {
            Swal.close();
            toast.success("User deleted successfully");

            window.location.href = "/search-user";
          })
          .catch((err) => {
            Swal.close();
            toast.error(err.response.data.message);
          });
        break;
      case "update_membership_cancellation_active_reminder_admin":
        updateMembershipCancellationActiveReminderAdminCall({
          active_reminder_process: false,
          token: userData?.membershipCancellationToken,
        })
          .then(() => {
            Swal.close();
            toast.success("Membership cancellation updated successfully");
          })
          .catch((err) => {
            Swal.close();
            toast.error(err.response.data.message);
          });
        break;
      case "update_user":
        updateUser(modifiedData)
          .then(() => {
            Swal.close();
            toast.success("User updated successfully");
          })
          .catch((err) => {
            Swal.close();
            toast.error(err.response.data.message);
          });
        break;
      default:
        break;
    }
  };

  const startLoading = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  return (
    <>
      <span data-toggle="modal" data-target={`#${id}`}>
        {ModalTrigger}
      </span>
      <div
        className="modal fade"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title "
                style={{ color: "#F9629F" }}
                id="exampleModalLongTitle"
              >
                {ModalTitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{color:"black"}}>{ModalBody}</div>
            <div className="modal-footer">
              <button
                type="button"
                className={buttonConfig.cancelButton.style}
                data-dismiss="modal"
                disabled={disabled}
                onClick={() => handleSubmit()}
              >
                {buttonConfig.cancelButton.text}
              </button>
              <button
                type="button"
                className={buttonConfig.saveButton.style}
                data-dismiss="modal"
              >
                {buttonConfig.saveButton.text}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
