import React, {useEffect, useState} from "react";
import {getInStoreActionsCall, getOnlineActionsCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {appToastMessages, appUrls, toastOptions} from "../../../config/Constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import {hasAValue} from "../../../utils/SharedFunctions";

export default function Step2({
                                  type,
                                  notificationItem,
                                  setNotificationItem,
                                  body,
                                  setBody,
                                  title,
                                  setTitle,
                                  mode,
                                  goBack,
                                  goNextStep
                              }) {
    const [actions, setActions] = useState([])
    const [actionsFiltered, setActionsFiltered] = useState([])

    useEffect(() => {
        if (type.toString().includes("instore")) {
            getInStoreActions()
        } else {
            getOnlineActions()
        }
    }, [])


    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2">
                        <div className="form-group mb-3">
                            <label className="text-label">Title*</label>
                            <input
                                type="text"
                                name="title"
                                className="form-control mb-3"
                                defaultValue={title}
                                onChange={val => {
                                    setTitle(val.target.value)
                                }}
                                required
                            />
                        </div>
                        <div className="form-group mb-3">
                            <div className={"d-flex justify-content-between"}>
                                <label className="text-label">Body*</label>
                                <label className="text-label">200/{hasAValue(body) ? body.toString().length : 0}</label>
                            </div>

                            <textarea
                                name="body"
                                rows={4}
                                maxLength={200}
                                className="form-control mb-3"
                                defaultValue={body}
                                onChange={val => {
                                    setBody(val.target.value)
                                }}
                                required
                            />
                        </div>

                        {/*url type*/}
                        {type.toString().includes("url") && <div className="form-group mb-3">
                            <label className="text-label">Url*</label>
                            <input
                                type="text"
                                name="body"
                                className="form-control mb-3"
                                defaultValue={notificationItem}
                                onChange={val => {
                                    setNotificationItem(val.target.value)
                                }}
                                required
                            />
                        </div>}


                        {/*instore type*/}
                        {type.toString().includes("instore") && <div className="form-group mb-3">
                            <label className="text-label">Instore action*</label>
                            <div className="form-group mb-3">
                                <div className="card border-0 pb-0">
                                    <div className="card-body border-top shadow rounded">
                                        {notificationItem && <div className="widget-media mb-2">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-2">
                                                            <img alt="" width="50"
                                                                 src={appUrls.backend_api_image + notificationItem.iconId}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.companyName}</h5>
                                                            <small className="d-block">
                                                                {notificationItem.localization.nl.subtitle}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}

                                        <input
                                            type="text"
                                            name="body"
                                            className="form-control mb-2"
                                            onChange={val => {
                                                handleInputSearchItemInStore(val.target.value)
                                            }}
                                            required
                                        />
                                        <PerfectScrollbar
                                            style={{height: "370px"}}
                                            id="DZ_W_Todo2"
                                            className="widget-media overflow-auto height370 ps ps--active-y"
                                        >
                                            <ul className="timeline">
                                                {actionsFiltered.map((item, i) => {
                                                    let selected = false
                                                    if (item === notificationItem) {
                                                        selected = true
                                                    }
                                                    return (
                                                        <li key={i} className={"cursor-pointer"}
                                                            onClick={() => setNotificationItem(item)}>
                                                            <div className={"timeline-panel"}>
                                                                <div className="media me-2">
                                                                    <img alt="" width="50"
                                                                         src={appUrls.backend_api_image + item.iconId}/>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-1">{item.companyName}</h5>
                                                                    <small className="d-block">
                                                                        {item.localization.nl.subtitle}
                                                                    </small>
                                                                </div>
                                                                {selected && <h3><i
                                                                    className="fas fa-check-circle"></i></h3>}
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>}


                        {/*online type*/}

                        {type.toString().includes("online") && <div className="form-group mb-3">
                            <label className="text-label">Online action*</label>
                            <div className="form-group mb-3">
                                <div className="card border-0 pb-0">
                                    <div className="card-body border-top shadow rounded">
                                        {notificationItem && <div className="widget-media mb-2">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-2">
                                                            <img alt="" width="50"
                                                                 src={notificationItem.advertiser.image_url}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.advertiser.name}</h5>
                                                            <small className="d-block">
                                                                {notificationItem.premium}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}
                                        <input
                                            type="text"
                                            name="body"
                                            className="form-control mb-2"
                                            onChange={val => {
                                                handleInputSearchItemOnline(val.target.value)
                                            }}
                                            required
                                        />
                                        <PerfectScrollbar
                                            style={{height: "370px"}}
                                            id="DZ_W_Todo2"
                                            className="widget-media overflow-auto height370 ps ps--active-y"
                                        >
                                            <ul className="timeline">
                                                {actionsFiltered.map((item, i) => {
                                                    let selected = false
                                                    if (item === notificationItem) {
                                                        selected = true
                                                    }
                                                    return (
                                                        <li key={i} className={"cursor-pointer"}
                                                            onClick={() => setNotificationItem(item)}>
                                                            <div className={"timeline-panel"}>
                                                                <div className="media me-2">
                                                                    <img alt="" width="50"
                                                                         src={item.advertiser.image_url}/>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-1">{item.advertiser.name}</h5>
                                                                    <small className="d-block">
                                                                        {item.premium}
                                                                    </small>
                                                                </div>
                                                                {selected && <h3><i
                                                                    className="fas fa-check-circle"></i></h3>}
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </PerfectScrollbar>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => goBack()}>Prev
                </button>
                <button className="btn btn-primary sw-btn-next ms-1" onClick={() => {
                    goNext()
                }}>Next
                </button>
            </div>
        </>
    );

    function goNext(){
        if(hasAValue(body) && hasAValue(title) && hasAValue(notificationItem)){
            goNextStep()
        }else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions)
        }
    }

    function getInStoreActions() {
        getInStoreActionsCall().then(results => {
            setActions(results)
            setActionsFiltered(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getOnlineActions() {
        getOnlineActionsCall().then(results => {
            setActions(results)
            setActionsFiltered(results)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


    function handleInputSearchItemInStore(searchValue) {
        setActionsFiltered(actions)
        let filteredData = actions.filter(element => {
            return (element.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
                    element.localization.nl.subtitle.toLowerCase().includes(searchValue.toLowerCase())) &&
                (element.companyName.toLowerCase().includes(searchValue.toLowerCase())
                    || (element.localization.nl.subtitle.toLowerCase().includes(searchValue.toLowerCase())));
        });
        setActionsFiltered(filteredData)
    };

    function handleInputSearchItemOnline(searchValue) {
        setActionsFiltered(actions)
        let filteredData = actions.filter(element => {
            return element.advertiser.name.toLowerCase().includes(searchValue.toLowerCase()) || element.app.body.toLowerCase().includes(searchValue.toLowerCase()) &&
                element.advertiser.name.toLowerCase().includes(searchValue.toLowerCase()) || element.app.body.toLowerCase().includes(searchValue.toLowerCase());
        });

        setActionsFiltered(filteredData)
    };
};



