export const appUrls = {
    // backend_api: "http://localhost:3001/", // local
    backend_api: process.env.REACT_APP_BACKEND_API, // production

    // backend_jobs: "http://localhost:3001/", // local
    backend_jobs: "https://knaek-jobs.it-plus24-hosting.com/", // production

    backend_api_image: "https://img.knaek.com/", // production
}

export const appLocalStoargeValues = {
    language: 'language',
    user_data: 'user_data',
}


export const appToastMessages = {
    words_data_required: 'You didnt put any words in the fields',
    all_fields_are_required: 'All fields are required',
    notification_has_not_been_sent: 'Notification has not been sent',
}


export const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit:5
};
