import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import {cellStyle, hasAValue} from "../../utils/SharedFunctions";
export const columns_notification = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Mode',
		Footer : 'Mode',
		accessor: 'mode',
		Filter: ColumnFilter,
	},
	{
		Header : 'Type',
		Footer : 'Type',
		accessor: 'type',
		Filter: ColumnFilter,
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
	},
	{
		Header : 'Title',
		Footer : 'Title',
		accessor: 'title',
		Filter: ColumnFilter,
	},
	{
		Header : 'Body',
		Footer : 'Body',
		accessor: 'body',
		Cell: ({ value }) => {return cellStyle(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Url',
		Footer : 'Url',
		accessor: 'url',
		Cell: ({ value }) => {return cellStyle(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Action',
		Footer : 'Action',
		accessor: 'action_id',
		Filter: ColumnFilter,
	},
	{
		Header : 'Made by',
		Footer : 'Made by',
		accessor: 'made_by',
		Cell: ({ value }) => {return hasAValue(value)? value : "Unknown"},
		Filter: ColumnFilter,
	},
	{
		Header : 'Created',
		Footer : 'Created',
		accessor: 'created_at',
		Cell: ({ value }) => {return (value.toString().substring(0,10) + " " +value.toString().substring(11,16))},
		Filter: ColumnFilter,
	},
	{
		Header : 'Total receivers',
		Footer : 'Total receivers by',
		accessor: 'total_receivers',
		Filter: ColumnFilter,
	},
	{
		Header : 'Firebase sent',
		Footer : 'Firebase sent',
		accessor: 'firebase_sent',
		Filter: ColumnFilter,
	},

	{
		Header : 'iOS sent',
		Footer : 'iOS sent',
		accessor: 'ios_sent',
		Filter: ColumnFilter,
	}
]
