import React, {Fragment, useEffect, useState} from "react";
import {Stepper, Step} from 'react-form-stepper';
import Step1 from "../../../components/Notifications/NewNotificationWizard/Step1";
import Step2 from "../../../components/Notifications/NewNotificationWizard/Step2";
import Step4 from "../../../components/Notifications/NewNotificationWizard/Step4";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {toast} from "react-toastify";
import {sendNotificationCall} from "../../../Api/ApiCalls";
import swal from "sweetalert";
import Step3 from "../../../components/Notifications/NewNotificationWizard/Step3";
import Swal from "sweetalert2";


export default function NewNotification() {
    const [goSteps, setGoSteps] = useState(1);
    const [mode, setMode] = useState("test");
    const [type, setType] = useState("url");
    const [title, setTitle] = useState(undefined);
    const [body, setBody] = useState(undefined);
    const [notificationItem, setNotificationItem] = useState(undefined);
    const [userListForTestMode, setUserListForTestMode] = useState([]);
    const [selectedFiltersForProductionMode, setSelectedFiltersForProductionMode] = useState(undefined)
    const [filterLimited, setFilterLimited] = useState("null")
    const [filterGender, setFilterGender] = useState("null")
    const [filterSession, setFilterSession] = useState("null")
    const [selectedFiltersCities, setSelectedFiltersCities] = useState([])
    const [iosTargetDevice, setIosTargetDevice] = useState(true)
    const [androidTargetDevice, setAndroidTargetDevice] = useState(true)
    const [fake, setFake] = useState(false)
    const [country, setCountry] = useState(1)


    useEffect(()=>{
        console.log(filterSession,filterLimited, filterGender)
        setSelectedFiltersForProductionMode({
            country: country,
            cities: selectedFiltersCities,
            limitation: filterLimited,
            gender: filterGender,
            session: filterSession
        })
    },[country, filterSession,filterLimited, filterGender, selectedFiltersCities])

    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-xl-12 col-xxl-12">
                    <div className="card">
                        <div className="card-header">
                            {goSteps === 1 && <h4 className="card-title">Notification info - <a
                                className={mode.includes("test") ? "badge badge-success" : "badge badge-danger"}>{hasAValue(mode) && mode}</a>
                            </h4>}
                            {goSteps === 2 && <h4 className="card-title">Notification - <a
                                className={mode.includes("test") ? "badge badge-success" : "badge badge-danger"}>{hasAValue(mode) && mode}</a>
                            </h4>}
                            {goSteps === 3 && <h4 className="card-title">Target - <a
                                className={mode.includes("test") ? "badge badge-success" : "badge badge-danger"}>{hasAValue(mode) && mode}</a>
                            </h4>}
                            {goSteps === 4 && <h4 className="card-title">Controle & send - <a
                                className={mode.includes("test") ? "badge badge-success" : "badge badge-danger"}>{hasAValue(mode) && mode}</a>
                            </h4>}
                        </div>
                        <div className="card-body">

                            <div className="form-wizard ">
                                <Stepper className="nav-wizard" activeStep={goSteps - 1} label={false}>
                                    <Step className="nav-link" onClick={() => setGoSteps(1)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(2)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(3)}/>
                                    <Step className="nav-link" onClick={() => setGoSteps(4)}/>
                                </Stepper>


                                {goSteps === 1 &&
                                        <Step1
                                            type={type} setType={(item) => {
                                            setNotificationItem(undefined)
                                            setType(item)
                                        }}
                                            goNextStep={()=> goNextStep()}
                                            mode={mode} setMode={(item) => setMode(item)}
                                        />
                                    }


                                {goSteps === 2 &&
                                        <Step2 type={type} mode={mode}
                                               title={title} setTitle={(item) => setTitle(item)}
                                               body={body} setBody={(item) => setBody(item)}
                                               notificationItem={notificationItem}
                                               setNotificationItem={(item) => setNotificationItem(item)}
                                               goNextStep={()=> goNextStep()}
                                               goBack={()=> goBack()}
                                        />}


                                {goSteps === 3 &&
                                        <Step3 userListForTestMode={userListForTestMode}
                                               setUserListForTestMode={(item) => setUserListForTestMode(item)}
                                               goNextStep={()=> goNextStep()}
                                               goBack={()=> goBack()}
                                               mode={mode}
                                               fake={fake}
                                               country={country}
                                               filterLimited={filterLimited}
                                               filterGender={filterGender}
                                               filterSession={filterSession}
                                               selectedFiltersCities={selectedFiltersCities}
                                               setSelectedFiltersCities={(value)=> setSelectedFiltersCities(value)}
                                               iosTargetDevice={iosTargetDevice}
                                               androidTargetDevice={androidTargetDevice}
                                               setIosTargetDevice={(value)=> setIosTargetDevice(value)}
                                               setAndroidTargetDevice={(value)=> setAndroidTargetDevice(value)}
                                               setFake={(value)=> setFake(value)}
                                               setCountry={(value)=> setCountry(value)}
                                               setFilterLimited={(value)=>setFilterLimited(value)}
                                               setFilterGender={(value)=>setFilterGender(value)}
                                               setFilterSession={(value)=>setFilterSession(value)}
                                        />

                                }

                                {goSteps === 4 &&
                                        <Step4
                                            mode={mode} type={type} title={title} body={body}
                                            selectedFiltersForProductionMode={selectedFiltersForProductionMode}
                                            notificationItem={notificationItem}
                                            userListForTestMode={userListForTestMode}
                                            goNextStep={()=> goNextStep()}
                                            goBack={()=> goBack()}
                                            sendNotification={()=> sendNotification()}
                                        />

                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );

    function goNextStep() {
        setGoSteps(goSteps + 1)
    }

    function goBack(){
        setGoSteps(goSteps - 1)
    }

    function sendNotification() {
        let data = {mode, type, title, body, notificationItem, userList: mode.includes("test")? userListForTestMode :selectedFiltersForProductionMode, iosTargetDevice, androidTargetDevice, fake}
        swal({
            title: "Are you sure?",
            text:
                "You want to send this message!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let isTest = mode.includes("test")? true : false
                Swal.fire({
                    title: "Loading...",
                    allowOutsideClick: true,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                    icon: "info",
                    onBeforeOpen: () => {
                        Swal.showLoading()
                    },
                });
                sendNotificationCall(data, isTest).then(success => {
                    Swal.close();
                    toast.success(success.data.message, toastOptions)
                    setTimeout(() => {
                        window.location = "/notification-overview"
                    }, 3000)

                }).catch(error => {
                    handleError(error)
                })
            } else {
                toast.warn(appToastMessages.notification_has_not_been_sent, toastOptions);
            }
        })
    }
};

