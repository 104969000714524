import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';
import {countryCodesCol} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {
    addCountryCodeCall,
    deleteCountryCodeCall,
    fetchAllCountryCodesCall,
} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import {appUrls, toastOptions} from "../../../config/Constants";
import er from "react-datepicker";
import { handleError } from '../../../utils/SharedFunctions';


export default function CountryCodes() {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [id, setId] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [label, setLabel] = useState(undefined);

    if (!countryCodesCol.some(e => e.Header === 'Action')) {
        countryCodesCol.push({
            Header: 'Action',
            Footer: 'Action',
            id: 'action',
            accessor: (originalRow, rowIndex) => {
                return (
                    <div>
                        <button className="btn btn-danger p-3" onClick={() => handleDelete(originalRow.id)}>
                            <i className="la la-trash fs-24"></i>
                        </button>
                    </div>
                )
            }
        });
    }

    useEffect(() => {
        setColumns(countryCodesCol);
        getCountryCodes();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination);

    const {globalFilter, pageIndex} = state

    return (
        <>
            <div className="card">
                <div className="card-header row">
                    <div className="col-9"><h4 className="card-title">Country Codes</h4></div>
                    <div className="col-3">
                        <button className="btn btn-secondary p-3" onClick={openModalEdit}>
                            <i className="la la-plus fs-24"></i>
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                            > {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal fade" size={"xl"} show={showModal} onHide={resetModalState}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Country Code</h4>
                                <button type="button" className="btn-close" onClick={resetModalState}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className="row">
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Value</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           onChange={(val) => setValue(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Label</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           onChange={(val) => setLabel(val.target.value)}
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={createCountryCode}>Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )


    function openModalEdit() {
        setShowModal(true);
    }

    function getCountryCodes() {
        fetchAllCountryCodesCall().then(_data => {
            setData(_data.countryCodes);
        }).catch(error => {
            handleError(error)
        });
    }

    function resetModalState() {
        setShowModal(false);
        setValue(undefined);
        setLabel(undefined);
    }

    function handleDelete(id) {
        deleteCountryCodeCall(id).then(success => {
            toast.success(success.data.message, toastOptions);
            setData(data.filter(e => e.id !== id));
        }).catch(error => {
            handleError(error)
        })
    }

    function createCountryCode() {
        if (!value || !label) {
            toast.error('Value and Label fields are required!', toastOptions);
            return;
        }
        let payload = {
            label,
            value
        };
        addCountryCodeCall(payload).then(success => {
            setData([...data, success.data]);
            resetModalState();
        }).catch(error => {
            handleError(error)
        });
    }
}
