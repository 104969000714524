// EraseUserModal.js
import React from "react";
import Modal from "./Modal";
import { EraseUser } from "../constant/modals";

const EraseUserModal = ({ id, ModalTrigger,userData }) => {
  return (
    <Modal
      id={id}
      ModalTitle="This will automatically cancel any active subscriptions and end KWM for user!"
      ModalBody={<EraseUser />}
      ModalTrigger={ModalTrigger}
      Theme="erase-user"
      endpoint="delete_user"
      userData={userData}
    />
  );
};

export default EraseUserModal;
