import React, {useState} from 'react'
import logo from "../../../style/images/knaek/1024x1024.png";
import {hasAValue, isValidEmail} from "../../../utils/SharedFunctions";
import {loginCall, resetPasswordCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {useDispatch, useSelector} from "react-redux";
import {updateUserData} from "../../../redux/actions/storeActions";

export default function Login(props) {
    const [email, setEmail] = useState(undefined);
    let errorsObj = {email: '', password: ''};
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState(undefined);
    const userData = useSelector(state => state.userData);
    const [resetPasswordMode, setResetPasswordMode] = useState(false);
    const dispatch = useDispatch();

    return (
        <>
            <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-aside text-center d-flex justify-content-center flex-column flex-row-auto">
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
                        <div className="text-center mb-4">
                            <img src={logo} className={"rounded"} alt="" width={150}/>
                        </div>
                        <h3 className="mb-2">Welcome back!</h3>
                    </div>

                </div>
                <div
                    className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
                    <div className="d-flex justify-content-center h-100 align-items-center">
                        <div className="authincation-content style-2">
                            <div className="row no-gutters">
                                <div className="col-xl-12 tab-content">
                                    <div className="auth-form form-validation">
                                        {props.errorMessage && (
                                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                                {props.errorMessage}
                                            </div>
                                        )}
                                        {props.successMessage && (
                                            <div
                                                className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                                {props.successMessage}
                                            </div>
                                        )}
                                        <div className="form-validate">
                                            <h3 className="text-center mb-4 text-black">{resetPasswordMode ? 'Reset Password' : 'Sign in your account'}</h3>
                                            <div className="form-group mb-3">
                                                <label className="mb-1"
                                                       htmlFor="val-email"><strong>Email</strong></label>
                                                <div>
                                                    <input type="email" className="form-control"
                                                           value={email}
                                                           onChange={(e) => setEmail(e.target.value)}
                                                           placeholder="Type Your Email Address"
                                                    />
                                                </div>
                                                {errors.email &&
                                                    <div className="text-danger fs-12">{errors.email}</div>}
                                            </div>
                                            {!resetPasswordMode &&
                                                <div className="form-group mb-3">
                                                    <label className="mb-1"><strong>Password</strong></label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        value={password}
                                                        placeholder="Type Your Password"
                                                        onChange={(e) =>
                                                            setPassword(e.target.value)
                                                        }
                                                    />
                                                    {errors.password &&
                                                        <div className="text-danger fs-12">{errors.password}</div>}
                                                </div>
                                            }
                                            <div className="text-center form-group mb-3">
                                                <button className="btn btn-primary btn-block"
                                                        onClick={() => resetPasswordMode ? resetPassword() : signIn()}>
                                                    {resetPasswordMode ? 'Reset Password' : 'Sign In'}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <a className="btn btn-link"
                                               onClick={() => setResetPasswordMode(!resetPasswordMode)}>
                                                {resetPasswordMode ? 'Sign In' : 'Forgot Password?'}
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    function signIn() {
        let data = {email, password}
        if (hasAValue(password) && hasAValue(email)) {
            loginCall(data).then(response => {
                const data = response
                toast.success('You have successfully logged in.', toastOptions);
                dispatch(updateUserData({
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken
                }))
                window.location = "/dashboard"
            }).catch(error => {
                toast.error(error.response.data.message, toastOptions);
            })
        } else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }
    }

    function resetPassword() {
        const data = {email}
        if (email && isValidEmail(email)) {
            resetPasswordCall(data).then(response => {
                toast.success('Reset link with instruction has been sent to your email!', toastOptions);
                // setEmail('');
                setResetPasswordMode(false)
            }).catch(error => {
                toast.error(error.response?.data?.payload?.message?.[0] ?? error.message, toastOptions);
            });
        } else {
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }
    }
}
