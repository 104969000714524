import {format} from 'date-fns';
import {ColumnFilter} from './ColumnFilter';
import {cellStyle, humanDate} from "../../utils/SharedFunctions";

export const COLUMNS = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'First Name',
        Footer: 'First Name',
        accessor: 'first_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Last Name',
        Footer: 'Last Name',
        accessor: 'last_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email Id',
        Footer: 'Email Id',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Date of  Birth',
        Footer: 'Date of  Birth',
        accessor: 'date_of_birth',
        Cell: ({value}) => {
            return format(new Date(value), 'dd/mm/yyyy')
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Country',
        Footer: 'Country',
        accessor: 'country',
        Filter: ColumnFilter,
    },
    {
        Header: 'Phone',
        Footer: 'Phone',
        accessor: 'phone',
        Filter: ColumnFilter,
    },
]

export const actions_col = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'actionId',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Title',
        Footer: 'Title',
        accessor: 'localization',
        Cell: ({value}) => {
            return cellStyle(value.en.title)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Company name',
        Footer: 'Company name',
        accessor: 'companyName',
        Filter: ColumnFilter,
    },
    {
        Header: 'Valid untill',
        Footer: 'Valid untill',
        accessor: 'validUntill',
        Filter: ColumnFilter,
    },
]

export const educationInstitutionCol = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Domain',
        Footer: 'Domain',
        accessor: 'domain',
        Filter: ColumnFilter,
    },
    {
        Header: 'brin',
        Footer: 'brin',
        accessor: 'brin',
        Filter: ColumnFilter,
    },
    {
        Header: 'Education level',
        Footer: 'Education level',
        accessor: 'educationLevel.name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'createdAt',
        Cell: ({value}) => {
            return humanDate(value);
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated at',
        Footer: 'Updated at',
        accessor: 'updatedAt',
        Cell: ({value}) => {
            return humanDate(value);
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'City',
        Footer: 'City',
        accessor: 'city.name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Free Distribution',
        Footer: 'Free Distribution',
        accessor: 'isFreeDistribution',
        Cell: ({value}) => {
            return <i className={value ? "text-secondary la la-check fs-24" : "text-danger la la-close fs-24"}></i>;
        },
        Filter: ColumnFilter,
    },
]

export const adminUsersCol = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Phone Number',
        Footer: 'Phone Number',
        accessor: 'phoneNumber',
        Filter: ColumnFilter,
    },
    {
        Header: 'Is Deleted',
        Footer: 'Is Deleted',
        accessor: 'isDeleted',
        Filter: ColumnFilter,
        Cell: ({value}) => {
            return <i className={value ? "text-danger la la-close fs-24" : "text-secondary la la-check fs-24"}></i>;
        },
    },
]


export const introduction_events_col = [
    {
        Header: 'Education name',
        Footer: 'Education name',
        accessor: 'education_name',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'City',
        Footer: 'City',
        accessor: 'city',
        Filter: ColumnFilter,
    },
    {
        Header: 'Finished processes',
        Footer: 'Finished processes',
        accessor: 'membership_done_count',
        Filter: ColumnFilter,
    },
    {
        Header: 'Account owner',
        Footer: 'Account owner',
        accessor: 'country',
        Filter: ColumnFilter,
    },
    {
        Header: 'Discount code',
        Footer: 'Discount code',
        accessor: 'discount_code',
        Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'is_active',
        Cell: (val) => val ? "Active" : "Not Active",
        Filter: ColumnFilter,
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated at',
        Footer: 'Updated at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
]


export const unsubscribe_events_col = [
    // {
    // 	Header : 'Id',
    // 	Footer : 'Id',
    // 	accessor: 'id_cancellation',
    // 	Filter: ColumnFilter,
    // 	//disableFilters: true,
    // },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Subscription id',
        Footer: 'Subscription id',
        accessor: 'subscription_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Cancellation status',
        Footer: 'Cancellation status',
        accessor: 'membership_cancellation_status',
        Filter: ColumnFilter,
    },
    {
        Header: 'Payment status',
        Footer: 'Payment status',
        accessor: 'payment_status',
        Filter: ColumnFilter,
    },
    {
        Header: 'Next reminder date',
        Footer: 'Next reminder date',
        accessor: 'next_reminder_date',
        Filter: ColumnFilter,
    },
    {
        Header: 'Reminders level',
        Footer: 'Reminders level',
        accessor: 'reminders_level',
        Filter: ColumnFilter,
    },
    {
        Header: 'Active reminder process',
        Footer: 'Active reminder process',
        accessor: 'active_reminder_process',
        Cell: ({value}) => {
            return value === true ? "YES" : "NO"
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Mollie payment id',
        Footer: 'Mollie payment id',
        accessor: 'mollie_payment_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Mollie payment link',
        Footer: 'Mollie payment link',
        accessor: 'mollie_payment_link',
        Filter: ColumnFilter,
    },

    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated at',
        Footer: 'Updated at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    }
]

export const system_user_forms_col = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Forms',
        Footer: 'Forms',
        accessor: 'forms',
        Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'status',
        Filter: ColumnFilter,
    },
    {
        Header: 'created_at',
        Footer: 'created_at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'updated_at',
        Footer: 'updated_at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
]

export const membership_form_order_col = [
    {
        Header: 'Contributor',
        Footer: 'Contributor',
        accessor: 'register_user_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Mandate number',
        Footer: 'Mandate number',
        accessor: 'form_number',
        Filter: ColumnFilter,
    },
    {
        Header: 'Knaek Id',
        Footer: 'Knaek Id',
        accessor: 'knaek_id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'First name',
        Footer: 'First name',
        accessor: 'first_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Last name',
        Footer: 'Last name',
        accessor: 'last_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Gender',
        Footer: 'Gender',
        accessor: 'gender',
        Filter: ColumnFilter,
    },
    {
        Header: 'Birthday',
        Footer: 'Birthday',
        accessor: 'birthday',
        Filter: ColumnFilter,
    },
    {
        Header: 'Iban',
        Footer: 'Iban',
        accessor: 'iban',
        Filter: ColumnFilter,
    },
    {
        Header: 'Country code',
        Footer: 'Country code',
        accessor: 'country_code',
        Filter: ColumnFilter,
    },
    {
        Header: 'Phone number',
        Footer: 'Phone number',
        accessor: 'phone_number',
        Filter: ColumnFilter,
    },

    {
        Header: 'Country',
        Footer: 'Country',
        accessor: 'country',
        Filter: ColumnFilter,
    },

    {
        Header: 'created_at',
        Footer: 'created_at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'updated_at',
        Footer: 'updated_at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
]

export const locations_col = [
    {
        Header: 'Location Id',
        Footer: 'Location Id',
        accessor: 'locationId',
        Filter: ColumnFilter,
    },
    {
        Header: 'Company Id',
        Footer: 'Company Id',
        accessor: 'companyId',
        Filter: ColumnFilter,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Address',
        Footer: 'Address',
        accessor: 'address',
        Filter: ColumnFilter,
    },
    {
        Header: 'City',
        Footer: 'City',
        accessor: 'city',
        Filter: ColumnFilter,
    },
    {
        Header: 'Latitude',
        Footer: 'Latitude',
        accessor: 'latitude',
        Filter: ColumnFilter,
    },
    {
        Header: 'Longitude',
        Footer: 'Longitude',
        accessor: 'longitude',
        Filter: ColumnFilter,
    },

]


export const localStoresCol = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Country',
        Footer: 'Country',
        accessor: ({culture}) => {
            return culture.includes('NL') ? 'Netherlands' : 'Belgium'
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Title',
        Footer: 'Title',
        accessor: 'title',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'App Weight',
        Footer: 'App Weight',
        accessor: 'appWeight',
        Filter: ColumnFilter,
    },
    {
        Header: 'Web Weight',
        Footer: 'Web Weight',
        accessor: 'webWeight',
        Filter: ColumnFilter,
    },
    {
        Header: 'Short Description',
        Footer: 'Short Description',
        accessor: 'nl.shortDes',
        Filter: ColumnFilter,
    },
    {
        Header: 'Full Description',
        Footer: 'Full Description',
        accessor: 'nl.fullDes',
        Filter: ColumnFilter,
    },
    {
        Header: 'Background Image',
        Footer: 'Background Image',
        accessor: 'background',
        Filter: ColumnFilter,
    },
    {
        Header: 'Enabled',
        Footer: 'Enabled',
        accessor: 'disabled',
        Cell: ({value}) => {
            return <i className={value ? "text-danger la la-close fs-24" : "text-secondary la la-check fs-24"}></i>;
        },
        Filter: ColumnFilter,
    }
]

export const orders_col = [
    {
        Header: 'First name',
        Footer: 'First name',
        accessor: 'first_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Last name',
        Footer: 'Last name',
        accessor: 'last_name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Student email',
        Footer: 'Student email',
        accessor: 'student_email',
        Cell: ({value}) => {
            return cellStyle(value)
        },
        Filter: ColumnFilter,
    },
    {
        Header: 'Origin',
        Footer: 'Origin',
        accessor: 'origin',
        Filter: ColumnFilter,
    },
    {
        Header: 'Country code',
        Footer: 'Country code',
        accessor: 'country_code',
        Filter: ColumnFilter,
    },
    {
        Header: 'Phone number',
        Footer: 'Phone number',
        accessor: 'phone_number',
        Filter: ColumnFilter,
    },
    {
        Header: 'Order status',
        Footer: 'Order status',
        accessor: 'status',
        Filter: ColumnFilter,
    },
    {
        Header: 'Channel',
        Footer: 'Channel',
        accessor: 'chanel',
        Filter: ColumnFilter,
    },
    {
        Header: 'Country',
        Footer: 'Country',
        accessor: 'country',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Cell: ({value}) => {
            return humanDate(value);
        },
        Filter: ColumnFilter,
    },
    // {
    //     Header: 'Mollie payment link',
    //     Footer: 'Mollie payment link',
    //     accessor: 'mollie_payment_link',
    //     Filter: ColumnFilter,
    // },
    // {
    //     Header: 'Mollie payment id',
    //     Footer: 'Mollie payment id',
    //     accessor: 'mollie_payment_id',
    //     Filter: ColumnFilter,
    // },
    {
        Header: 'Intro name',
        Footer: 'Intro name',
        accessor: 'intro_name',
        Filter: ColumnFilter,
    },

]

export const countryCodesCol = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Label',
        Footer: 'Label',
        accessor: 'label',
        Filter: ColumnFilter,
    },
    {
        Header: 'Value',
        Footer: 'Value',
        accessor: 'value',
        Filter: ColumnFilter,
    },
]
