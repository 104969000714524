import React, {Suspense, useContext, useEffect, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {userLoggedIn} from "../utils/SharedFunctions";
import {ThemeContext} from "../config/context/ThemeContext";
import Nav from "../components/nav";
import Footer from "../components/nav/Footer";
import Setting from "../components/nav/Setting";
import ScrollToTop from "../components/nav/ScrollToTop";

// importing all the themes
import "../style/css/index.css";
import "../style/css/chart.css";
import "../style/css/step.css";
import {privatePages, publicPages} from "./RoutesHelper";

export default function MyRouts() {
    let [userData, setUserData] = useState(false)

    useEffect(async () => {
        setUserData(await userLoggedIn())
    }, [])


    const {menuToggle} = useContext(ThemeContext);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    let pagePath = path.split("-").includes("page") || path.split("-").includes("") || window.location.pathname.includes('wachtwoord-vergeten');

    return (
        <div className={!userData ? "vh-100" : ""}>
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>}>


                <div id={!pagePath ? "main-wrapper" : ""}
                     className={!pagePath ? "show " : "mh100vh " + menuToggle ? " menu-toggle" : " "}>
                    {!pagePath && <Nav/>}
                    <div className={!pagePath ? "content-body" : ""}>
                        <div className={!pagePath ? "container-fluid" : ""}
                             style={{minHeight: window.screen.height - 60}}>
                            {privatePages()}
                        </div>
                    </div>
                    {!pagePath && <Footer/>}
                </div>
                {/*<Setting/>*/}
                <ScrollToTop/>
            </Suspense>
        </div>
    );
}
