import React, {useEffect, useMemo, useState} from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';

//import './table.css';
import './../FilteringTable/filtering.css';
import {columns_notification} from "../FilteringTable/NotificationTabelsColumns";
import {GlobalFilter} from "../FilteringTable/GlobalFilter";
import PageTitle from "../nav/PageTitle";
import {fetchAllNotificationsCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {Modal} from "react-bootstrap";


export default function ToSendNotification(){
    const [notifications, setNotifications] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationElement, setNotificationElement] = useState(undefined)

    useEffect(()=>{
        fetchAllNotifications()
    },[])



    const columns = useMemo( () => columns_notification, [] )
    const data = useMemo( () => notifications, [] )
    const tableInstance = useTable({
        columns,
        data,
        initialState : {pageIndex : 0}
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return(
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Filtering</h4>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                            {column.canFilter ? column.render('Filter') : null}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()} className="" >

                            {page.map((row) => {
                                prepareRow(row)
                                return(
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"} onClick={() => openModalShow(row.original)}> {cell.render('Cell')} </td>
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange = {e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       } }
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Notification to send</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Title</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           // defaultValue={notificationElement.mode}
                                                    />

                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/*{!showEdit && <div className="modal-footer">*/}

                            {/*    <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i*/}
                            {/*        className="flaticon-delete-1"></i> Wijzigingen negeren*/}
                            {/*    </button>*/}
                            {/*    <button type="button" className="btn btn-secondary"*/}
                            {/*            onClick={() => addContactPersonAdmin()}>Toevoegen*/}
                            {/*    </button>*/}
                            {/*</div>}*/}

                         <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => {}}>Edit</button>
                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
                                    className="flaticon-delete-1"></i> Close
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalShow() {
        // console.log(item)
        alert("sd")
        // setShowModal(true)
        // setNotificationElement(item)
    }

    function fetchAllNotifications(){
        fetchAllNotificationsCall().then(results=>{
            setNotifications(results)
        }).catch(error=>{
            toast.error(error.response.data.error,toastOptions)
        })
    }
}
