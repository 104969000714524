import React, { Fragment, useEffect, useState } from "react";
import { getChannels, searchUserCall } from "../../../Api/ApiCalls";
import Swal from "sweetalert2";
import { usePagination, useTable } from "react-table";
import { columns_user } from "../../../components/FilteringTable/UserTableColumn";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { handleError } from "../../../utils/SharedFunctions";
import ExtensiveSearchForm from "./forms/ExtensiveSearch/ExtensiveSearchForm";
import { ApiSearchField } from "../../../components/ApiSearchField";

export default function UserSearch() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [channels, setChannels] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");

  const getDefaultSearchData = () => ({
    email: "",
    studentEmail: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    knaekID: "",
    mandateNumber: "",
    iban: "",
    creditCard: "",
    accountOwner: "",
    channelId: "",
    countryId: "",
    cityId: "",
    educationInstitutionId: "",
    extensiveSearch: false,
  });
  const [searchData, setSearchData] = useState(getDefaultSearchData());

  useEffect(() => {
    setColumns(columns_user);
    fetchData(currentPage, searchData);
    fetchChannels().then();
  }, []);

  useEffect(() => {
    fetchData(currentPage,searchData);
  }, [searchQuery]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const startLoading = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const fetchChannels = async () => {
    try {
      const data = await getChannels();
      setChannels(data);
    } catch (error) {
      console.error("Error fetching channels:", error);
      handleError(error);
    }
  };


  const fetchData = (pageIndex, formData) => {
    if (pageIndex < 1) return;
    if(searchQuery === "") {
      startLoading()
    }
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== undefined && value !== ""
      )
    );
    searchUserCall({ ...filteredFormData, page: pageIndex, size: 10,query: searchQuery })
      .then((result) => {
        Swal.close();
        setData(result.items);
        setTotalPages(result.totalPages);
        setCurrentPage(result.page);
        setTargetPage(result.page + 1);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleExtensiveSearch = (formData) => {
    setSearchData(() => formData);
    fetchData(1, formData);
  };

  return (
    <>
      <Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Search user</h4>
                <h4 className="card-title">
                  Search Result ({totalPages * 10})
                </h4>
              </div>
              <div className="card-body">
                <div class="d-flex row">
                  <div className="left-main col-4">
                    <div className="d-flex justify-content-between mb-4">
                      {/* <Button
                        text="Filter Search"
                        variant="pink"
                        data-toggle="modal"
                        onClick={() => setExtensiveSearch(!ExtensiveSearch)}
                      />
                      <Button
                        text="Extensive Search"
                        variant="pink"
                        data-toggle="modal"
                        onClick={() => setExtensiveSearch(!ExtensiveSearch)}
                      /> */}
                    </div>
                    {/* {ExtensiveSearch ? ( */}
                    <ExtensiveSearchForm
                      handleExtensiveSearch={handleExtensiveSearch}
                      startLoading={startLoading}
                      channels={channels}
                      searchQuery={searchQuery}
                    />
                    {/* ) : ( */}
                    {/* <UserSearchForm
                        channels={channels}
                        cities={cities}
                        countries={countries}
                        educationInstitutions={educationInstitutions}
                        handleSearchSubmit={handleSearchSubmit}
                      /> */}
                    {/* )} */}
                  </div>
                  <div className="right-main col-8 mt-4">
                    <ApiSearchField
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      
                    />
                    <table
                      {...getTableProps()}
                      className="table dataTable display"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()} className="">
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr
                              {...row.getRowProps()}
                              onClick={() => {
                                const userId = row.original.id;
                                history.push({
                                  pathname: `/user/${userId}`,
                                });
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {" "}
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                      <span>
                        Page{" "}
                        <strong>
                          {currentPage} of {totalPages}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Go to page :{}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={currentPage + 1}
                          value={currentPage + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value)
                              : 1;
                            setTargetPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => {
                            fetchData(currentPage - 1, searchData);
                          }}
                          disabled={currentPage === 1}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => fetchData(currentPage - 1, searchData)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => fetchData(targetPage, searchData)}
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => fetchData(targetPage, searchData)}
                          disabled={currentPage === totalPages}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
}
