import React,{ useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import {Dropdown, Tab, Nav} from 'react-bootstrap';

import room4 from '../../../style/images/profile/1.jpg';
import room5 from '../../../style/images/profile/1.jpg';
import room6 from '../../../style/images/profile/1.jpg';
import room7 from '../../../style/images/profile/1.jpg';
import AllNotification from "../../../components/Notifications/AllNotification";
import ToSendNotification from "../../../components/Notifications/ToSendNotification";
//import GuestCarousel from './Room/GuestCarousel';


const NotificationOverview = () =>{
    const [selectBtn, setSelectBtn] = useState("Newest");
    const [data, setData] = useState(
        document.querySelectorAll("#room_wrapper tbody tr")
    );
    const sort = 10;
    const activePag = useRef(0);


    // Active data
    const chageData = (frist, sec) => {
        for (var i = 0; i < data.length; ++i) {
            if (i >= frist && i < sec) {
                data[i].classList.remove("d-none");
            } else {
                data[i].classList.add("d-none");
            }
        }
    };
    // use effect
    useEffect(() => {
        setData(document.querySelectorAll("#room_wrapper tbody tr"));
        //chackboxFun();
    }, []);


    // Active pagginarion
    activePag.current === 0 && chageData(0, sort);
    // paggination
    let paggination = Array(Math.ceil(data.length / sort))
        .fill()
        .map((_, i) => i + 1);

    // Active paggination & chage data
    const onClick = (i) => {
        activePag.current = i;
        chageData(activePag.current * sort, (activePag.current + 1) * sort);

    };


    const chackbox = document.querySelectorAll(".sorting_7 input");
    const motherChackBox = document.querySelector(".sorting_asc_7 input");
    // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
    const chackboxFun = (type) => {
        for (let i = 0; i < chackbox.length; i++) {
            const element = chackbox[i];
            if (type === "all") {
                if (motherChackBox.checked) {
                    element.checked = true;
                } else {
                    element.checked = false;
                }
            } else {
                if (!element.checked) {
                    motherChackBox.checked = false;
                    break;
                } else {
                    motherChackBox.checked = true;
                }
            }
        }
    };
    return(
        <>
            <Tab.Container defaultActiveKey="All">
                <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
                    <div className="card-action coin-tabs mb-2">
                        <Nav as="ul" className="nav nav-tabs" role="tablist">
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link className="nav-link" eventKey="All">All notifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link className="nav-link" eventKey="toSend">To send</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        <Link to={"/new-notification"} className="btn btn-secondary">+ New Notification</Link>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <Tab.Content>
                                    <Tab.Pane eventKey="All">
                                      <AllNotification/>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="toSend">
                                       <ToSendNotification/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Container>
        </>
    )
}
export default NotificationOverview;
