import React, {Fragment, useContext, useEffect, useState} from 'react';
import {unsubscribeMemberCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";


export default function Unsubscribe() {
    const [email, setEmail] = useState(undefined)
    const [expiration_date, setExpiration_date] = useState(undefined)
    const [no_payment_link, setNo_payment_link] = useState(false)
    const [selectedDataPicker, setSelectedDataPicker] = useState(new Date())


    return (
        <>
            <Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Unsubscribe member</h4>
                            </div>
                            <div className="card-body">

                                <section>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group mb-3">
                                                <label className="text-label">Email</label>
                                                <input
                                                    name="title"
                                                    className="form-control mb-3"
                                                    defaultValue={email}
                                                    onChange={val => {
                                                        setEmail(val.target.value)
                                                    }}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 mb-2">
                                            <div className="form-group mb-3">
                                                <p className="mb-1">Cancellation date</p>
                                                <DatePicker className="form-control"
                                                            selected={selectedDataPicker}
                                                            onChange={(val) => {
                                                                setSelectedDataPicker(val)
                                                                setExpiration_date(val.toISOString())
                                                            }}/>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mb-2">
                                            <div className="form-group mb-3">
                                                <p className="mb-1">No payment link?</p>
                                                <input
                                                    type="checkbox"
                                                    defaultValue={no_payment_link}
                                                    checked={no_payment_link}
                                                    className="form-check-input"
                                                    onChange={() => setNo_payment_link(!no_payment_link)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className="text-end toolbar toolbar-bottom p-2">
                                    <button className="btn btn-primary sw-btn-next ms-1"
                                            onClick={() => unsubscribeMember()}>Unsubscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )


    function unsubscribeMember() {
        if (hasAValue(email) && hasAValue(expiration_date)) {
            let data = {email, expiration_date, no_payment_link}
            unsubscribeMemberCall(data).then(success => {
                toast.success(success.message, toastOptions)
                setTimeout(() => {
                    window.open(success.link)
                }, 3000)
            }).catch(error => {
                handleError(error)
            })
        }
    }


}
