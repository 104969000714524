import InputField from "../common/Input";

export const EndKWM = () => {
  return (
    <div className="d-flex flex-column ">
      <p>
        User will receive no more emails as text messages with payment requests.
        Payment status will be changed to 'paid'
      </p>
      <p>However, the subscription will NOT automatically be canceled.</p>
    </div>
  );
};

export const Warning = () => {
  return (
    <div className="d-flex flex-column ">
      <p>
        Attention! You are about to modify critical data. Do you really want to
        update this data? Click 'Update' to proceed
      </p>
    </div>
  );
};

export const EraseUser = () => {
  return (
    <p>
      This will automatically cancel any active subscriptions and end KWM for
      user!
    </p>
  );
};

export const CancelSubscription = ({
  inputLabel,
  inputValue,
  inputPlaceholder,
  inputOnChange,
  checkboxOnChange,
  noPaymentLink,
}) => {
  return (
    <div className="d-flex flex-column gap-1">
      <InputField
        label={inputLabel}
        onChange={inputOnChange}
        value={inputValue}
        placeholder={inputPlaceholder}
        type="date"
      />

      <div className="form-check ">
        <input
          style={{
            cursor: "pointer",
          }}
          className="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          checked={noPaymentLink}
          onChange={() => {
            checkboxOnChange();
          }}
        />
        <label
          style={{
            cursor: "pointer",
            userSelect: "none",
          }}
          className="form-check-label mt-1"
          for="flexCheckDefault"
        >
          No payment-link or KWM
        </label>
      </div>
    </div>
  );
};
