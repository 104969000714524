import React, { useState } from "react";
import InputField from "../../common/Input";
import Filters from "../FilterSearch/components/UserFilter";

const ExtensiveSearchForm = ({
  handleExtensiveSearch,
  channels,
  searchQuery,
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    studentEmail: "",
    phoneNumber: "",
    knaekID: "",
    mandateNumber: "",
    iban: "",
    creditCard: "",
    accountOwner: "",
    channel: "",
    extensiveSearch: false,
  });

  const handleInputChange = (label, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      extensiveSearch: !prevData.extensiveSearch,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleExtensiveSearch(formData);
  };

  return (
    <form className="form d-flex flex-column gap-4" onSubmit={handleFormSubmit}>
      <div className="d-flex gap-4 justify-content-between">
        <InputField
          className={"w-50"}
          label="First Name"
          value={formData.firstName}
          onChange={(e) => handleInputChange("firstName", e.target.value)}
        />
        <InputField
          className={"w-50"}
          label="Last Name"
          value={formData.lastName}
          onChange={(e) => handleInputChange("lastName", e.target.value)}
        />
      </div>
      <InputField
        label="Email"
        value={formData.email}
        onChange={(e) => handleInputChange("email", e.target.value)}
      />
      <InputField
        label="Student email"
        value={formData.studentEmail}
        onChange={(e) => handleInputChange("studentEmail", e.target.value)}
      />
      <InputField
        label="Phone number"
        value={formData.phoneNumber}
        onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
      />
      <div className="d-flex gap-4 justify-content-between">
        <InputField
          className={"w-50"}
          label="Knaek-ID"
          value={formData.knaekID}
          onChange={(e) => handleInputChange("knaekID", e.target.value)}
        />
        <InputField
          className={"w-50"}
          label="Mandate number"
          value={formData.mandateNumber}
          onChange={(e) => handleInputChange("mandateNumber", e.target.value)}
        />
      </div>
      <InputField
        label="IBAN"
        value={formData.iban}
        onChange={(e) => handleInputChange("iban", e.target.value)}
      />
      <InputField
        label="Credit card"
        value={formData.creditCard}
        onChange={(e) => handleInputChange("creditCard", e.target.value)}
      />
      <div className="d-flex gap-4 justify-content-between">
        {/*Account owner*/}
        <div className="d-flex flex-column mb-2 w-50">
          <label>Account owner</label>
          <div className="contact-name">
            <select
              className="bg-beige rounded form-control border-1"
              name="user_type"
              onChange={(e) =>
                handleInputChange("accountOwner", e.target.value)
              }
            >
              <option value={""}>All</option>
              <option value={"Knaek NL"}>Knaek NL</option>
              <option value={"Knaek BE"}>Knaek BE</option>
            </select>
          </div>
        </div>
        {/*Account owner*/}
        <div className="d-flex flex-column mb-2 w-50">
          <label>Channel</label>
          <div className="contact-name">
            <select
              className="bg-beige rounded form-control border-1"
              name="user_type"
              onChange={(e) => handleInputChange("channelName", e.target.value)}
            >
              <option value={""}>All</option>
              <option value={"INTRO_PAGE"}>INTRO_PAGE</option>
              <option value={"ANALOGUE"}>ANALOGUE</option>
              <option value={"KNAEK_WEBSITE"}>KNAEK_WEBSITE</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bottom d-flex">
        <div className="d-flex  align-items-center flex-start w-50">
          <input
            type="checkbox"
            checked={formData.extensiveSearch}
            onChange={handleCheckboxChange}
          />
          <label className="mx-1 my-1">Extensive search</label>
        </div>
        <div className="d-flex justify-content-between mt-3 w-50">
          <div className="text-end w-100 toolbar toolbar-bottom">
            <button
              className="w-100 btn btn-primary sw-btn-next"
              type="submit"
              disabled={searchQuery !== ""}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ExtensiveSearchForm;
