import { useEffect, useState } from "react";
import FormField from "../common/FormField";
import { getCities, getEducationInstitutions } from "../../../../Api/ApiCalls";

const EntityModal = ({ userData, handleEntityUpdate }) => {
  const [cities, setCities] = useState([]);
  const [educationInstitution,setEducationInstitution] = useState([])

  useEffect(() => {
    getCitiesForDropDown();
    getEducationInstitutionForDropDown();
  }, []);

  const getCitiesForDropDown = async () => {
    try {
      getCities()
        .then((res) => {
          setCities(res)
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };

  const getEducationInstitutionForDropDown = async () => {
    try {
      getEducationInstitutions()
        .then((res) => {
          console.log(res)
          setEducationInstitution(res)
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };

  return (
    <div className="modal-body">
      <i className="flaticon-cancel-12 close"></i>
      <div className="add-contact-box">
        <div className="add-contact-content">
          <div className="row">
            {/* Personal Data */}
            <label className="h2" style={{ color: "#f53c79" }}>
              Personal Data
            </label>
            <FormField
              label="First Name"
              name="firstName"
              value={userData?.firstName}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Email"
              name="email"
              value={userData?.email}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Phone Number"
              name="phoneNumber"
              value={userData?.phoneNumber}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Gender"
              name="gender"
              value={userData?.gender}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
              type="select"
              options={[
                { name: "male" },
                { name: "female" },
                { name: "other" },
              ]}
            />
            <FormField
              label="Last Name"
              name="lastName"
              value={userData?.lastName}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Student Email"
              name="studentEmail"
              value={userData?.studentEmail}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Birthdate"
              name="birthdate"
              value={userData?.birthdate}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
          </div>
          <div className="">
            {/* Study */}

            <div className="">
              <label className="h2" style={{ color: "#f53c79" }}>
                Study
              </label>
              <div className="d-flex">
                <div className="w-50">
                  <FormField
                    label="Institution"
                    name="institution"
                    value={userData?.institution}
                    onChange={handleEntityUpdate}
                    type="select"
                    options={educationInstitution}
                  />
                </div>

                <div className="w-50">
                  <FormField
                    label="Student City"
                    name="studentCity"
                    value={""}
                    onChange={handleEntityUpdate}
                    type="select"
                    options={cities}
                  />
                </div>
              </div>
            </div>
            {/* App */}
            {/* <div className="row w-50">
              <label className="h2" style={{ color: "#f53c79" }}>
                App
              </label>
              <FormField
                label="App Status"
                name="appStatus"
                value={userData?.appStatus}
                onChange={handleEntityUpdate}
              />
              <FormField
                label="Knaek-ID"
                name="knaekCode"
                value={userData?.knaekCode?.code}
                onChange={handleEntityUpdate}
              />
              <FormField
                label="Knaek-ID expiration date"
                name="knaekCodeExpirationDate"
                value={userData?.knaekCode?.expirationDate}
                onChange={handleEntityUpdate}
              />
            </div> */}
          </div>
          <div className="row">
            {/* Account Data */}
            <label className="h2" style={{ color: "#f53c79" }}>
              Account Data
            </label>
            <FormField
              label="Account owner"
              name="accountOwner"
              value={userData?.accountOwner}
              onChange={handleEntityUpdate}
              type="select"
              options={[{ name: "Knaek NL" }, { name: "Knaek BE" }]}
              className={"col-md-3"}
            />
            <FormField
              label="Mandate number"
              name="mandateNumber"
              value={userData?.mandateNumber}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Bank account name"
              name="bankAccountName"
              value={userData?.paymentAccountDetails?.bankAccountName}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="BIC"
              name="bic"
              value={userData?.paymentAccountDetails?.bic}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Authorization reference"
              name="authorizationReference"
              value={userData?.incassoReference}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="IBAN"
              name="iban"
              value={userData?.paymentAccountDetails?.iban}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
            <FormField
              label="Credit Card"
              name="creditCardNumber"
              value={userData?.paymentAccountDetails?.creditCardNumber}
              onChange={handleEntityUpdate}
              className={"col-md-3"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityModal;
