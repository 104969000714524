import React, { useEffect, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table";
import Swal from "sweetalert2";
import swal from 'sweetalert'
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { adminUsersCol } from "../../../components/FilteringTable/Columns";
import { toastOptions } from "../../../config/Constants";
import { handleError, hasAValue } from "../../../utils/SharedFunctions";
import {
  createUserAdmin,
  deleteAdminUserCall,
  getAllAdminUsers,
  updateAdminUser,
} from "../../../Api/ApiCalls";

export default function AdminUsers() {
  const [adminUsers, setAdminUsers] = useState([]);
  const [columns, setColumns] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);


  const [formData, setFormData] = useState({
    id: 0,
    email: "",
    password: "",
    phoneNumber: "",
  });

  useEffect(() => {
    setColumns(adminUsersCol);
    fetchData(currentPage);
  }, []);

  const startLoading = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const fetchData = (pageIndex) => {
    if (pageIndex < 1) return;
    startLoading();
    getAllAdminUsers({ page: pageIndex, size: 10 })
      .then((result) => {
        Swal.close();
        setAdminUsers(result.items);
        setTotalPages(result.totalPages);
        setCurrentPage(result.page);
        setTargetPage(result.page + 1);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        Swal.close();
        handleError(error);
      });
  };

  const tableInstance = useTable(
    {
      columns,
      data: adminUsers,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Admin Overview</h4>
          <a className="btn btn-secondary" onClick={() => openModalAdd()}>
            + Add New Admin
          </a>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i
                                className="fa fa-arrow-down ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            ) : (
                              <i
                                className="fa fa-arrow-up ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            )
                          ) : (
                            <i
                              className="fa fa-sort ms-2 fs-14"
                              style={{ opacity: "0.3" }}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={"cursor-pointer"}
                            onClick={() => openModalEdit(row.original)}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* This is only for footer if u require */}
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {currentPage} of {totalPages}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={currentPage + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value)
                      : 1;
                    setTargetPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => {
                    fetchData(currentPage - 1);
                  }}
                  disabled={currentPage === 1}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => fetchData(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => fetchData(targetPage)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => fetchData(targetPage)}
                  disabled={currentPage === totalPages}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade"
        size={"xl"}
        show={showModal}
        onHide={setShowModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Admin User</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className={"row"}>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Email</label>
                        <div className="contact-name">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required="required"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Phone Number
                        </label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            defaultValue={formData.phoneNumber}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phoneNumber: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      {!showEdit && (
                        <div className="form-group mb-3 col-sm-10 col-md-6">
                          <label className="text-black font-w500">
                            Password
                          </label>
                          <div className="contact-name">
                            <input
                              type="text"
                              className="form-control"
                              name="password"
                              defaultValue={formData.password}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  password: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!showEdit && (
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => createAdminUser()}
                  >
                    Add
                  </button>
                </div>
              )}

              {showEdit && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => updateUserAdmin()}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="btn btn-warning"
                  >
                    <i className="flaticon-delete-1"></i> Ignore changes
                  </button>

                  <button
                    type="button"
                    onClick={() => deleteAdminUser()}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Delete
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );

  function openModalEdit(adminUser) {
    setFormData({
      id: adminUser.id,
      email: adminUser.email,
      password: adminUser.password,
      phoneNumber: adminUser.phoneNumber,
    });

    setShowModal(true);
    setShowEdit(true);
  }

  function openModalAdd() {
    setFormData({
      email: "",
      password: "",
      phoneNumber: "",
    });
    setShowModal(true);
    setShowEdit(false);
  }

  function createAdminUser() {
    if (
      !hasAValue(formData.email) ||
      !hasAValue(formData.password) ||
      !hasAValue(formData.phoneNumber)
    ) {
      toast.error("All fields are required", toastOptions);
      return;
    }

    createUserAdmin(formData)
      .then((result) => {
        setShowModal(false);
        toast.success("A New admin user has been added", toastOptions);
        fetchData(currentPage);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function updateUserAdmin() {
    if (!hasAValue(formData.email) || !hasAValue(formData.phoneNumber)) {
      toast.error("All fields are required", toastOptions);
      return;
    }

    const formDataSent = {
      id: formData.id,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
    };

    updateAdminUser(formDataSent)
      .then((result) => {
        setShowModal(false);
        toast.success("Admin User has been updated successfully", toastOptions);
        fetchData(currentPage);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function deleteAdminUser() {
    swal({
      title: "Are you sure?",
      text: "You want to delete this admin user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        const formDataSent = {
          id: formData.id
        }
        deleteAdminUserCall(formDataSent)
        .then((result) => {
          setShowModal(false);
          toast.success("Admin User has been deleted successfully", toastOptions);
          fetchData(currentPage);
        })
        .catch((error) => {
          handleError(error);
        });
      }
    });
  }
}
