import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { educationInstitutionCol } from "../../../components/FilteringTable/Columns";
import {
  createEducationInstitutionCall,
  deleteEducationalInstitutionCall,
  fetchAllCitiesCall,
  fetchEducationsCall,
  fetchAllEducationsLevelsCall,
  updateEducationalInstitutionCall,
} from "../../../Api/ApiCalls";
import { appToastMessages, toastOptions } from "../../../config/Constants";
import { handleError, hasAValue } from "../../../utils/SharedFunctions";
import { ApiSearchField } from "../../../components/ApiSearchField";
import { useLocation } from "react-router-dom";

export default function EducationalInstitutionOverview() {
  const [institutions, setInstitutions] = useState([]);
  const [totalPages, setTotalPages] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(undefined);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [name, setName] = useState(undefined);
  const [domain, setDomain] = useState(undefined);
  const [brin, setBrin] = useState(undefined);
  const [isFreeDistribution, setIsFreeDistribution] = useState(false);
  const [educationLevelId, setEducationLevelId] = useState(undefined);
  const [cityId, setCityId] = useState(undefined);
  const [institutionId, setInstitutionId] = useState(undefined);

  const [cities, setCities] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();

  useEffect(() => {
    setColumns(educationInstitutionCol);
    fetchEducations(1);
    fetchAllCities();
    fetchAllEducationsLevels();
  }, []);

  useEffect(() => {
    fetchEducations(1);
  }, [location.search]);

  useEffect(() => {
    fetchEducations(1);
  }, [searchQuery]);

  const tableInstance = useTable(
    {
      columns,
      data: institutions,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  const { pageIndex } = state;

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Educational institution</h4>
          <a className="btn btn-secondary" onClick={() => openModalAdd()}>
            + Add new educational institution
          </a>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <ApiSearchField
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i
                                className="fa fa-arrow-down ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            ) : (
                              <i
                                className="fa fa-arrow-up ms-2 fs-14"
                                style={{ opacity: "0.7" }}
                              />
                            )
                          ) : (
                            <i
                              className="fa fa-sort ms-2 fs-14"
                              style={{ opacity: "0.3" }}
                            />
                          )}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={"cursor-pointer"}
                            onClick={() => openModalEdit(row.original)}
                          >
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* This is only for footer if u require */}
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {currentPage} of {totalPages}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value)
                      : 1;
                    fetchEducations(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => fetchEducations(1)}
                  disabled={currentPage === 1}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => fetchEducations(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => fetchEducations(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => fetchEducations(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade"
        size={"xl"}
        show={showModal}
        onHide={setShowModal}
      >
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Educational institution</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                  data-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className={"row"}>
                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Name</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={name}
                            onChange={(val) => setName(val.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Domain</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="domain"
                            defaultValue={domain}
                            onChange={(val) => setDomain(val.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">Brin</label>
                        <div className="contact-name">
                          <input
                            type="text"
                            className="form-control"
                            name="brin"
                            defaultValue={brin}
                            onChange={(val) => setBrin(val.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Education Level
                        </label>
                        <div className="contact-name">
                          <select
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={educationLevelId}
                            onChange={(val) =>
                              setEducationLevelId(val.target.value)
                            }
                          >
                            <option value={null}>{"Choose one"}</option>
                            {educationLevels.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">City</label>
                        <div className="contact-name">
                          <select
                            className="form-control"
                            name="name"
                            required="required"
                            defaultValue={cityId}
                            onChange={(val) => setCityId(val.target.value)}
                          >
                            <option value={null}>{"Choose one"}</option>

                            {cities.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-3 col-sm-10 col-md-6">
                        <label className="text-black font-w500">
                          Free Distribution
                        </label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="isFreeDistribution"
                            checked={isFreeDistribution}
                            onChange={(val) =>
                              setIsFreeDistribution(val.target.checked)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!showEdit && (
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => createEducationInstitution()}
                  >
                    Add
                  </button>
                </div>
              )}

              {showEdit && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => updateEducationalInstitution()}
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="btn btn-warning"
                  >
                    <i className="flaticon-delete-1"></i> Ignore changes
                  </button>

                  <button
                    type="button"
                    onClick={() => deleteEducationalInstitution()}
                    className="btn btn-danger"
                  >
                    <i className="flaticon-delete-1"></i> Delete
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );

  function openModalEdit(institution) {
    setInstitutionId(institution.id);
    setCityId(institution.city?.id);
    setEducationLevelId(institution.educationLevel?.id);
    setName(institution.name);
    setDomain(institution.domain);
    setBrin(institution.brin);
    setIsFreeDistribution(institution.isFreeDistribution);

    setShowModal(true);
    setShowEdit(true);
  }

  function openModalAdd() {
    setCityId(undefined);
    setEducationLevelId(undefined);
    setName(undefined);
    setDomain(undefined);
    setBrin(undefined);
    setIsFreeDistribution(false);
    setShowModal(true);
    setShowEdit(false);
  }

  function getFilters() {
    const apiFilters = [];
    if (searchQuery?.length) {
      apiFilters.push(`name=${encodeURIComponent(searchQuery)}`);
    }
    return apiFilters.join("&");
  }

  function fetchEducations(page) {
    fetchEducationsCall(page, 10, getFilters())
      .then((result) => {
        setInstitutions(result.items);
        setTotalPages(result.totalPages);
        setCurrentPage(result.page);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function fetchAllCities() {
    fetchAllCitiesCall()
      .then((result) => {
        setCities(result);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function fetchAllEducationsLevels() {
    fetchAllEducationsLevelsCall()
      .then((result) => {
        setEducationLevels(result);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function createEducationInstitution() {
    const data = {
      name: name,
      domain: domain,
      brin: brin,
      isFreeDistribution: isFreeDistribution,
      educationLevelId: educationLevelId,
      cityId: cityId,
    };

    if (
      !hasAValue(name) ||
      !hasAValue(educationLevelId) ||
      !hasAValue(cityId)
    ) {
      toast.error(
        "Name, City and Education Level fields are required",
        toastOptions
      );
      return;
    }

    createEducationInstitutionCall(data)
      .then((result) => {
        setInstitutions([result, ...institutions]);
        setShowModal(false);
        toast.success(
          "A New education institution has been added",
          toastOptions
        );
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function updateEducationalInstitution() {
    const data = {
      name: name,
      domain: domain,
      brin: brin,
      isFreeDistribution: isFreeDistribution,
      educationLevelId: educationLevelId,
      cityId: cityId,
    };

    if (
      !hasAValue(name) ||
      !hasAValue(educationLevelId) ||
      !hasAValue(cityId)
    ) {
      toast.error(
        "Name, City and Education Level fields are required",
        toastOptions
      );
      return;
    }

    updateEducationalInstitutionCall(institutionId, data)
      .then((result) => {
        setInstitutions(
          institutions.map((i) =>
            i.id === institutionId ? { ...i, ...result } : i
          )
        );
        setShowModal(false);
        toast.success(
          "The education institution has been updated",
          toastOptions
        );
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function deleteEducationalInstitution() {
    swal({
      title: "Are you sure?",
      text: "You want to delete this institution!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((response) => {
      if (response) {
        deleteEducationalInstitutionCall(institutionId)
          .then(() => {
            setInstitutions(institutions.filter((i) => i.id !== institutionId));
            setShowModal(false);
            toast.success(
              "The education institution has been deleted",
              toastOptions
            );
          })
          .catch((error) => {
            handleError(error);
          });
      }
    });
  }
}
