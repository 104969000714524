import { useState } from "react";

const FormField = ({
  label,
  name,
  value,
  onChange,
  type = "text",
  options = [],
  required = false,
  className,
}) => {
  const [editableData, setEditableData] = useState(value);
  const handleContentChange = (event) => {
    setEditableData(event.target.value);
  };

  return (
    <div className={`form-group mb-3 col-sm-10 ${className}`}>
      <label className="text-black font-w500">{label}</label>
      <div className="contact-name">
        {type === "select" ? (
          <select
            className="form-select"
            style={{
              padding: "14px",
            }}
            id={name}
            value={editableData}
            onChange={(e) => {
              handleContentChange(e);
              onChange(label, e.target.value);
            }}
          >
            <option value="">{`Select ${label}`}</option>
            {options.map((option) => (
              <option key={option.id} value={option.name}>
                {option.name}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            className="form-control"
            name={name}
            value={editableData}
            onChange={(e) => {
              handleContentChange(e);
              onChange(label, e.target.value);
            }}
            required={required}
          />
        )}
      </div>
    </div>
  );
};

export default FormField;
