import "./style/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./style/css/style.css";
import MyRouts from './routes/Routes'
import {withRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';


import React from "react";
function App() {

    return (
        <>
            <ToastContainer limit={1}/>
            <MyRouts/>
        </>
    )


}

export default withRouter(App);
