import Entity from "./Entity";

const Section = ({ title, entities, onUpdate,editable=true }) => {

  const handleEntityUpdate = (label, newData) => {
    onUpdate(label, newData);
  };

  return (
    <div className="d-flex flex-column w-100 ">
      <div
        className="h3 font-weight-bold col-3 text-primary"   
      >
        {title}
      </div>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        {entities.map((entity, index) => (
          <Entity
            key={index}
            onUpdate={handleEntityUpdate}
            editable={editable}
            {...entity}
          />
        ))}
        <div className="col-3"></div>
      </div>
    </div>
  );
};

export default Section;
