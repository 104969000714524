import React, {Fragment, useState} from 'react';
import {fetchKnaekIdCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import { handleError } from '../../../utils/SharedFunctions';


//Import Components


export default function KnaekIdSearch() {

    const [knaekId, setKnaekId] = useState([])
    const [knaekIdData, setKnaekIdData] = useState([])


    return (
        <>

            <Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-12 col-xxl-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">Knaek ID</h4>
                            </div>
                            <div className="card-body">


                                    <div className="row justify-content-center align-content-center align-items-center">
                                                <div className="col-lg-8 col-sm-12 mb-2">
                                                    <label className="text-label">
                                                    Knaek ID status
                                                </label>
                                                    <input
                                                        name="title"
                                                        className="form-control mb-3"
                                                        onChange={val => {
                                                            setKnaekId(val.target.value.toUpperCase())
                                                        }}
                                                        style={{ textTransform: "uppercase" }}
                                                    />
                                                </div>
                                                <div className="col-lg-4 col-sm-12">
                                                    <button className="btn btn-primary sw-btn-next ms-1"
                                                            onClick={() => fetchKnaekId()}>
                                                        Check ID
                                                    </button>
                                                </div>
                                    </div>

                                <table className="table dataTable display">
                                    <thead>
                                    <tr>
                                        <th>
                                            <span className="ml-1">
                                                Valid
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Email
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Expiration date
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Available
                                            </span>
                                        </th>
                                        <th>
                                            <span className="ml-1">
                                                Is discount code
                                            </span>
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"cursor-pointer"}>
                                            {knaekIdData.valid ? "Yes" : "No"}
                                        </td>
                                        <td className={"cursor-pointer"}>
                                            {knaekIdData.email}
                                        </td>
                                        <td className={"cursor-pointer"}>
                                            {knaekIdData.expiration_date}
                                        </td>
                                        <td className={"cursor-pointer"}>
                                            {knaekIdData.available ? "Yes" : "No"}
                                        </td>
                                        <td className={"cursor-pointer"}>
                                            {knaekIdData.is_discount_code ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    )

    function fetchKnaekId() {
        fetchKnaekIdCall(knaekId).then(result => {
            setKnaekIdData(result)
        }).catch(error => {
            handleError(error)
        })
    }
}
